// THIS FILE IS GENERATED, DO NOT EDIT!
import * as Types from './types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type InsertProgresReportMutationVariables = Types.Exact<{
  report_name: Types.Scalars['String']['input'];
  deadline: Types.Scalars['date']['input'];
  dms_id: Types.Scalars['Int']['input'];
  template_id: Types.Scalars['uuid']['input'];
}>;


export type InsertProgresReportMutation = { __typename?: 'mutation_root', insertProgressReport?: { __typename?: 'InsertProgressReportOutput', id: any } | null };

export type InsertUserMutationVariables = Types.Exact<{
  object: Types.UsersInsertInput;
}>;


export type InsertUserMutation = { __typename?: 'mutation_root', insertUser?: { __typename?: 'users', id: any, displayName: string, email?: any | null, dms_id?: any | null, phoneNumber?: string | null, createdAt: any, disabled: boolean, defaultRole: Types.AuthRolesEnum, metadata?: any | null, institution?: { __typename?: 'institutions', id: any, type?: Types.TypeInstitutionsEnum | null, name: string, parent_id?: any | null } | null, roles: Array<{ __typename?: 'authUserRoles', id: any, role: Types.AuthRolesEnum, modules?: Array<string> | null }>, gnpies: Array<{ __typename?: 'gnpie', id: any, role: Types.AuthRolesEnum, chapter_id?: number | null, is_main_negotiator?: boolean | null, chapter?: { __typename?: 'chapters', id: number, chapter_number: string } | null }> } | null };

export type UpdateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  object: Types.UsersSetInput;
}>;


export type UpdateUserMutation = { __typename?: 'mutation_root', updateUser?: { __typename?: 'users', id: any, displayName: string, email?: any | null, phoneNumber?: string | null, defaultRole: Types.AuthRolesEnum, institution?: { __typename?: 'institutions', id: any, name: string, type?: Types.TypeInstitutionsEnum | null } | null } | null };

export type UpsertUserMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  user: Types.UsersSetInput;
  gnpies: Array<Types.GnpieInsertInput> | Types.GnpieInsertInput;
}>;


export type UpsertUserMutation = { __typename?: 'mutation_root', gnpies?: { __typename?: 'gnpie_mutation_response', returning: Array<{ __typename?: 'gnpie', id: any, role: Types.AuthRolesEnum, chapter_id?: number | null, created_by_role?: Types.AuthRolesEnum | null, is_main_negotiator?: boolean | null, chapter?: { __typename?: 'chapters', id: number, chapter_number: string } | null, cluster?: { __typename?: 'clusters', id: number, title: string } | null }> } | null, user?: { __typename?: 'users', displayName: string, email?: any | null, id: any, dms_id?: any | null, phoneNumber?: string | null, createdAt: any, disabled: boolean, defaultRole: Types.AuthRolesEnum, metadata?: any | null, institution?: { __typename?: 'institutions', id: any, type?: Types.TypeInstitutionsEnum | null, name: string, parent_id?: any | null } | null, roles: Array<{ __typename?: 'authUserRoles', id: any, role: Types.AuthRolesEnum, modules?: Array<string> | null }>, gnpies: Array<{ __typename?: 'gnpie', id: any, role: Types.AuthRolesEnum, chapter_id?: number | null, created_by_role?: Types.AuthRolesEnum | null, is_main_negotiator?: boolean | null, cluster?: { __typename?: 'clusters', id: number, title: string } | null, chapter?: { __typename?: 'chapters', id: number, chapter_number: string } | null }> } | null };

export type DeleteGnpieMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type DeleteGnpieMutation = { __typename?: 'mutation_root', delete_gnpie_by_pk?: { __typename?: 'gnpie', id: any } | null };

export type UpsertChapterInstitutionsMutationVariables = Types.Exact<{
  chapter_id: Types.Scalars['Int']['input'];
  objects: Array<Types.ChapterLeadInstitutionsInsertInput> | Types.ChapterLeadInstitutionsInsertInput;
}>;


export type UpsertChapterInstitutionsMutation = { __typename?: 'mutation_root', delete?: { __typename?: 'chapter_lead_institutions_mutation_response', affected_rows: number } | null, insert?: { __typename?: 'chapter_lead_institutions_mutation_response', affected_rows: number } | null };

export type UpsertSubChapterInstitutionsMutationVariables = Types.Exact<{
  sub_chapter_id: Types.Scalars['Int']['input'];
  objects: Array<Types.SubChapterLeadInstitutionsInsertInput> | Types.SubChapterLeadInstitutionsInsertInput;
}>;


export type UpsertSubChapterInstitutionsMutation = { __typename?: 'mutation_root', delete?: { __typename?: 'sub_chapter_lead_institutions_mutation_response', affected_rows: number } | null, insert?: { __typename?: 'sub_chapter_lead_institutions_mutation_response', affected_rows: number } | null };

export type InstitutionFragment = { __typename?: 'institutions', id: any, name: string, type?: Types.TypeInstitutionsEnum | null, qbz_id?: number | null, short_name?: string | null, parent_id?: any | null };

export type InsertNewInstitutionMutationVariables = Types.Exact<{
  object: Types.InstitutionsInsertInput;
}>;


export type InsertNewInstitutionMutation = { __typename?: 'mutation_root', insert_institutions_one?: { __typename?: 'institutions', id: any, name: string, type?: Types.TypeInstitutionsEnum | null, qbz_id?: number | null, short_name?: string | null, parent_id?: any | null } | null };

export type UpdateInstitutionMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  object: Types.InstitutionsSetInput;
}>;


export type UpdateInstitutionMutation = { __typename?: 'mutation_root', update_institutions_by_pk?: { __typename?: 'institutions', id: any, name: string, type?: Types.TypeInstitutionsEnum | null, qbz_id?: number | null, short_name?: string | null, parent_id?: any | null } | null };

export type UpdateManyReportTasksMutationVariables = Types.Exact<{
  set: Types.ReportTasksSetInput;
  where: Types.ReportTasksBoolExp;
}>;


export type UpdateManyReportTasksMutation = { __typename?: 'mutation_root', update_report_tasks_many?: Array<{ __typename?: 'report_tasks_mutation_response', affected_rows: number } | null> | null };

export type UpdateProgressReportMutationVariables = Types.Exact<{
  set?: Types.InputMaybe<Types.ReportsSetInput>;
  id: Types.Scalars['uuid']['input'];
}>;


export type UpdateProgressReportMutation = { __typename?: 'mutation_root', update_reports_by_pk?: { __typename?: 'reports', id: any } | null };

export type InsertRecommendationMutationVariables = Types.Exact<{
  object: Types.RecommendationsInsertInput;
}>;


export type InsertRecommendationMutation = { __typename?: 'mutation_root', insert_recommendations_one?: { __typename?: 'recommendations', id: any } | null };

export type InsertBookmarkMutationVariables = Types.Exact<{
  object: Types.BookmarksInsertInput;
}>;


export type InsertBookmarkMutation = { __typename?: 'mutation_root', insert_bookmarks_one?: { __typename?: 'bookmarks', id: number } | null };

export type EditQuestionMutationVariables = Types.Exact<{
  set: Types.QuestionsSetInput;
  id: Types.Scalars['uuid']['input'];
}>;


export type EditQuestionMutation = { __typename?: 'mutation_root', update_questions_by_pk?: { __typename?: 'questions', id: any, text: string } | null };

export type UpdateQuestionsStatusesMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
  status: Types.Scalars['Boolean']['input'];
}>;


export type UpdateQuestionsStatusesMutation = { __typename?: 'mutation_root', update_questions?: { __typename?: 'questions_mutation_response', affected_rows: number } | null };

export type UpdateBookmarkMutationVariables = Types.Exact<{
  bookmarkId: Types.Scalars['Int']['input'];
  object: Types.BookmarksSetInput;
}>;


export type UpdateBookmarkMutation = { __typename?: 'mutation_root', update_bookmarks_by_pk?: { __typename?: 'bookmarks', id: number, code_id: string, title: string, deadline: any, reference?: string | null, is_completed?: boolean | null, created_by_user_id?: any | null, assigned_to_user_id?: any | null, chapter_id?: number | null, sub_chapter_id?: number | null, topic_id?: number | null, bookmark_tasks: Array<{ __typename?: 'bookmark_tasks', id: any, status: Types.BookmarkTaskStatusesEnum }>, recommendation: { __typename?: 'recommendations', id: any, title: string }, assignee?: { __typename?: 'users', id: any, displayName: string } | null, chapter?: { __typename?: 'chapters', id: number, chapter_title: string, chapter_number: string } | null, sub_chapter?: { __typename?: 'sub_chapters', id: number, title?: string | null, sub_chapter_number?: string | null } | null, topic?: { __typename?: 'topics', id: number, title: string } | null } | null };

export type InsertQuestionMutationVariables = Types.Exact<{
  object: Types.QuestionsInsertInput;
}>;


export type InsertQuestionMutation = { __typename?: 'mutation_root', insert_questions_one?: { __typename?: 'questions', id: any } | null };

export type InsertBookmarkTaskMutationVariables = Types.Exact<{
  object: Types.BookmarkTasksInsertInput;
}>;


export type InsertBookmarkTaskMutation = { __typename?: 'mutation_root', insert_bookmark_tasks_one?: { __typename?: 'bookmark_tasks', id: any } | null };

export type InsertCommentIntoTaskMutationVariables = Types.Exact<{
  object: Types.ReportTaskCommentInsertInput;
}>;


export type InsertCommentIntoTaskMutation = { __typename?: 'mutation_root', insert_report_task_comment_one?: { __typename?: 'report_task_comment', id: any, comment: string, created_at: any, created_by_user: { __typename?: 'users', id: any, displayName: string } } | null };

export type InsertCommentIntoBookmarkTaskMutationVariables = Types.Exact<{
  object: Types.BookmarkTaskCommentInsertInput;
}>;


export type InsertCommentIntoBookmarkTaskMutation = { __typename?: 'mutation_root', insert_bookmark_task_comment_one?: { __typename?: 'bookmark_task_comment', id: any, comment: string, created_at: any, created_by_user: { __typename?: 'users', displayName: string } } | null };

export type InsertAnswerIntoBookmarkTaskMutationVariables = Types.Exact<{
  object: Types.BookmarkTaskAnswersInsertInput;
}>;


export type InsertAnswerIntoBookmarkTaskMutation = { __typename?: 'mutation_root', insert_bookmark_task_answers_one?: { __typename?: 'bookmark_task_answers', id: any, answer?: string | null, created_at: any, created_by_user: { __typename?: 'users', id: any, displayName: string } } | null };

export type InsertAnswerIntoReportTaskMutationVariables = Types.Exact<{
  object: Types.ReportAnswersInsertInput;
}>;


export type InsertAnswerIntoReportTaskMutation = { __typename?: 'mutation_root', insert_report_answers_one?: { __typename?: 'report_answers', id: any, answer?: string | null, created_at: any, has_update?: boolean | null, doc_ids?: any | null, user: { __typename?: 'users', id: any, displayName: string } } | null };

export type UpdateReportTaskStatusMutationVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
  taskStatus: Types.ReportTaskStatusesEnum;
}>;


export type UpdateReportTaskStatusMutation = { __typename?: 'mutation_root', update_report_tasks_by_pk?: { __typename?: 'report_tasks', id: any, status: Types.ReportTaskStatusesEnum } | null };

export type UpdateBookmarkTaskStatusMutationVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
  taskStatus: Types.BookmarkTaskStatusesEnum;
}>;


export type UpdateBookmarkTaskStatusMutation = { __typename?: 'mutation_root', update_bookmark_tasks_by_pk?: { __typename?: 'bookmark_tasks', id: any, status: Types.BookmarkTaskStatusesEnum } | null };

export type SetFinalAnswerOfTaskMutationVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
  answerId: Types.Scalars['uuid']['input'];
}>;


export type SetFinalAnswerOfTaskMutation = { __typename?: 'mutation_root', update_report_tasks_by_pk?: { __typename?: 'report_tasks', id: any } | null };

export type ChangeAssigneeInReportTaskMutationVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
  _set?: Types.InputMaybe<Types.ReportTasksSetInput>;
}>;


export type ChangeAssigneeInReportTaskMutation = { __typename?: 'mutation_root', update_report_tasks_by_pk?: { __typename?: 'report_tasks', id: any } | null };

export type ChangeAssigneeInBookmarkTaskMutationVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
  _set?: Types.InputMaybe<Types.BookmarkTasksSetInput>;
}>;


export type ChangeAssigneeInBookmarkTaskMutation = { __typename?: 'mutation_root', update_bookmark_tasks_by_pk?: { __typename?: 'bookmark_tasks', id: any, doc_ids?: any | null } | null };

export type InsertAcquisWorkPlanMutationVariables = Types.Exact<{
  obj: Types.CreateAcquisWorkPlanPkieWorkPlansInsertInput;
}>;


export type InsertAcquisWorkPlanMutation = { __typename?: 'mutation_root', insertAcquisWorkPlan?: { __typename?: 'CreateAcquisWorkPlanOutput', id: any } | null };

export type InsertCommentIntoWorkPlanTaskMutationVariables = Types.Exact<{
  object: Types.PkieWorkPlanCommentInsertInput;
}>;


export type InsertCommentIntoWorkPlanTaskMutation = { __typename?: 'mutation_root', insert_pkie_work_plan_comment_one?: { __typename?: 'pkie_work_plan_comment', id: any, comment: string, created_at: any, created_by_user: { __typename?: 'users', displayName: string } } | null };

export type UpdateWorkPlanTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  set?: Types.InputMaybe<Types.PkieWorkPlanTaskSetInput>;
}>;


export type UpdateWorkPlanTaskMutation = { __typename?: 'mutation_root', update_pkie_work_plan_task_by_pk?: { __typename?: 'pkie_work_plan_task', id: any } | null };

export type UpdateWorkPlanMutationVariables = Types.Exact<{
  set?: Types.InputMaybe<Types.PkieWorkPlansSetInput>;
  id: Types.Scalars['uuid']['input'];
}>;


export type UpdateWorkPlanMutation = { __typename?: 'mutation_root', update_pkie_work_plans_by_pk?: { __typename?: 'pkie_work_plans', id: any } | null };

export type InsertMeasureMutationVariables = Types.Exact<{
  object: Types.PkieMeasureInsertInput;
}>;


export type InsertMeasureMutation = { __typename?: 'mutation_root', insert_pkie_measure_one?: { __typename?: 'pkie_measure', id: any, acquis_work_plan_task_id?: any | null, text: string, year_of_completition?: number | null, type: Types.PkieTypeMeasureEnum, is_completed?: boolean | null, acquis_chapter?: { __typename?: 'pkie_acquis_chapter', id: any, celex: string } | null, negotiator?: { __typename?: 'users', id: any, displayName: string } | null, chief_gnpie_secretariat?: { __typename?: 'users', id: any, displayName: string } | null, gnpie_secretariat?: { __typename?: 'users', id: any, displayName: string } | null, created_by_user: { __typename?: 'users', id: any, displayName: string } } | null };

export type UpdateAcquisByPkMutationVariables = Types.Exact<{
  celex: Types.Scalars['String']['input'];
  set?: Types.InputMaybe<Types.PkieAcquisSetInput>;
}>;


export type UpdateAcquisByPkMutation = { __typename?: 'mutation_root', update_pkie_acquis_by_pk?: { __typename?: 'pkie_acquis', level_of_alignment?: Types.PkieLevelOfAlignmentEnum | null, is_aligned?: boolean | null, dms_id?: number | null } | null };

export type InsertAcquisInstitutionMutationVariables = Types.Exact<{
  objects: Array<Types.PkieAcquisInstitutionsInsertInput> | Types.PkieAcquisInstitutionsInsertInput;
}>;


export type InsertAcquisInstitutionMutation = { __typename?: 'mutation_root', insert_pkie_acquis_institutions?: { __typename?: 'pkie_acquis_institutions_mutation_response', returning: Array<{ __typename?: 'pkie_acquis_institutions', id: any }> } | null };

export type DeleteAcquisInstitutionByPkMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type DeleteAcquisInstitutionByPkMutation = { __typename?: 'mutation_root', delete_pkie_acquis_institutions_by_pk?: { __typename?: 'pkie_acquis_institutions', id: any } | null };

export type UpdateAcquisTaskByPkMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  set?: Types.InputMaybe<Types.PkieMeasureSetInput>;
}>;


export type UpdateAcquisTaskByPkMutation = { __typename?: 'mutation_root', update_pkie_measure_by_pk?: { __typename?: 'pkie_measure', level_of_alignment?: Types.PkieLevelOfAlignmentEnum | null, type_of_alignment?: Types.PkieTypeAlignmentActEnum | null, impact?: string | null, doc_ids?: any | null, id: any, acquis_work_plan_task_id?: any | null, text: string, quadrennial_scheduled_approval?: string | null, weight_transposition?: number | null, year_of_completition?: number | null, type: Types.PkieTypeMeasureEnum, is_completed?: boolean | null, national_act?: { __typename?: 'pkie_national_acts', eli: string, type?: Types.PkieActTypeEnum | null, title: string, qbz_id?: any | null, publish_date?: any | null } | null, acquis_chapter?: { __typename?: 'pkie_acquis_chapter', id: any, celex: string } | null, proposing_institution?: { __typename?: 'institutions', id: any, name: string } | null, negotiator?: { __typename?: 'users', id: any, displayName: string } | null, chief_gnpie_secretariat?: { __typename?: 'users', id: any, displayName: string } | null, gnpie_secretariat?: { __typename?: 'users', id: any, displayName: string } | null, created_by_user: { __typename?: 'users', id: any, displayName: string } } | null };

export type SetActToAcquisMutationVariables = Types.Exact<{
  celex: Types.Scalars['String']['input'];
  eli?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SetActToAcquisMutation = { __typename?: 'mutation_root', insert_pkie_acquis_national_acts_one?: { __typename?: 'pkie_acquis_national_acts', id: any, national_act: { __typename?: 'pkie_national_acts', id: any, qbz_id?: any | null, title: string, eli: string, act_number?: string | null, acquis_national_acts: Array<{ __typename?: 'pkie_acquis_national_acts', id: any }> } } | null };

export type DeletePkieAcquisNationalActsByPkMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type DeletePkieAcquisNationalActsByPkMutation = { __typename?: 'mutation_root', delete_pkie_acquis_national_acts_by_pk?: { __typename?: 'pkie_acquis_national_acts', national_act_eli: string } | null };

export type InsertCommentIntoAcquisTaskMutationVariables = Types.Exact<{
  object: Types.PkieMeasureCommentInsertInput;
}>;


export type InsertCommentIntoAcquisTaskMutation = { __typename?: 'mutation_root', insert_pkie_measure_comment_one?: { __typename?: 'pkie_measure_comment', id: any, comment: string, created_at: any, created_by_user: { __typename?: 'users', displayName: string } } | null };

export type InsertAnswerIntoAcquisTaskMutationVariables = Types.Exact<{
  object: Types.PkieMeasureAnswersInsertInput;
  measureId: Types.Scalars['uuid']['input'];
  status?: Types.InputMaybe<Types.PkieMeasureStatusEnum>;
  isCompleted?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type InsertAnswerIntoAcquisTaskMutation = { __typename?: 'mutation_root', insert_pkie_measure_answers_one?: { __typename?: 'pkie_measure_answers', id: any, answer?: string | null, doc_ids?: any | null, created_at: any, status?: string | null, created_by_user: { __typename?: 'users', displayName: string }, measure: { __typename?: 'pkie_measure', id: any, is_completed?: boolean | null } } | null, update_pkie_measure_by_pk?: { __typename?: 'pkie_measure', id: any, status?: Types.PkieMeasureStatusEnum | null, is_completed?: boolean | null } | null };

export type InsertMeasureBudgetMutationVariables = Types.Exact<{
  object: Types.PkieMeasureBudgetsInsertInput;
}>;


export type InsertMeasureBudgetMutation = { __typename?: 'mutation_root', insert_pkie_measure_budgets_one?: { __typename?: 'pkie_measure_budgets', id: any, type_of_budget?: Types.PkieTypeMeasureBudgetEnum | null, value?: any | null, year?: number | null, source_of_funding?: Types.PkieSourceOfFundingEnum | null } | null };

export type InsertMeasureBookmarksMutationVariables = Types.Exact<{
  objects: Array<Types.PkieMeasureBookmarkInsertInput> | Types.PkieMeasureBookmarkInsertInput;
}>;


export type InsertMeasureBookmarksMutation = { __typename?: 'mutation_root', insert_pkie_measure_bookmark?: { __typename?: 'pkie_measure_bookmark_mutation_response', returning: Array<{ __typename?: 'pkie_measure_bookmark', bookmark?: { __typename?: 'bookmarks', id: number, title: string, deadline: any, reference?: string | null } | null }> } | null };

export type DeleteMeasureBookmarkMutationVariables = Types.Exact<{
  bookmarkId: Types.Scalars['Int']['input'];
  measureId: Types.Scalars['uuid']['input'];
}>;


export type DeleteMeasureBookmarkMutation = { __typename?: 'mutation_root', delete_pkie_measure_bookmark?: { __typename?: 'pkie_measure_bookmark_mutation_response', returning: Array<{ __typename?: 'pkie_measure_bookmark', bookmark?: { __typename?: 'bookmarks', id: number, title: string } | null }> } | null };

export type UpdateMeasureBudgetByPkMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type UpdateMeasureBudgetByPkMutation = { __typename?: 'mutation_root', update_pkie_measure_budgets_by_pk?: { __typename?: 'pkie_measure_budgets', id: any } | null };

export type InsertMeetingMutationVariables = Types.Exact<{
  object: Types.MeetingsInsertInput;
}>;


export type InsertMeetingMutation = { __typename?: 'mutation_root', insert_meetings_one?: { __typename?: 'meetings', id: any } | null };

export type InsertNoteIntoMeetingMutationVariables = Types.Exact<{
  object: Types.MeetingNotesInsertInput;
}>;


export type InsertNoteIntoMeetingMutation = { __typename?: 'mutation_root', insert_meeting_notes_one?: { __typename?: 'meeting_notes', id: any, note: string, user: { __typename?: 'users', id: any, displayName: string } } | null };

export type InsertNoteIntoEventMutationVariables = Types.Exact<{
  object: Types.EventNotesInsertInput;
}>;


export type InsertNoteIntoEventMutation = { __typename?: 'mutation_root', insert_event_notes_one?: { __typename?: 'event_notes', id: any, note: string, user: { __typename?: 'users', id: any, displayName: string } } | null };

export type InsertGeneralTaskMutationVariables = Types.Exact<{
  object: Types.TaskInsertInput;
}>;


export type InsertGeneralTaskMutation = { __typename?: 'mutation_root', insert_task_one?: { __typename?: 'task', id: any, text?: string | null, deadline: any, assignee?: { __typename?: 'users', id: any, displayName: string } | null } | null };

export type InsertAnswerIntoGeneralTaskMutationVariables = Types.Exact<{
  object: Types.TaskAnswersInsertInput;
}>;


export type InsertAnswerIntoGeneralTaskMutation = { __typename?: 'mutation_root', insert_task_answers_one?: { __typename?: 'task_answers', id: any, answer: string, created_at: any, created_by_user: { __typename?: 'users', id: any, displayName: string } } | null };

export type UpdateGeneralTaskStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  status: Types.TaskStatusesEnum;
}>;


export type UpdateGeneralTaskStatusMutation = { __typename?: 'mutation_root', update_task_by_pk?: { __typename?: 'task', id: any } | null };

export type InsertCommentIntoGeneralTaskMutationVariables = Types.Exact<{
  object: Types.TaskCommentsInsertInput;
}>;


export type InsertCommentIntoGeneralTaskMutation = { __typename?: 'mutation_root', insert_task_comments_one?: { __typename?: 'task_comments', id: any, comment: string, created_at: any, created_by_user: { __typename?: 'users', id: any, displayName: string } } | null };

export type UpdateReportTasksStatusManyMutationVariables = Types.Exact<{
  set: Types.ReportTasksSetInput;
  where: Types.ReportTasksBoolExp;
}>;


export type UpdateReportTasksStatusManyMutation = { __typename?: 'mutation_root', update_report_tasks_many?: Array<{ __typename?: 'report_tasks_mutation_response', affected_rows: number } | null> | null };

export type CompleteReportSignatureMutationVariables = Types.Exact<{
  chapterId: Types.Scalars['Int']['input'];
  reportId: Types.Scalars['uuid']['input'];
  code: Types.Scalars['String']['input'];
}>;


export type CompleteReportSignatureMutation = { __typename?: 'mutation_root', completeReportSigniture?: { __typename?: 'completeSignitureOutput', message: string, success: boolean } | null };

export type ReadNotificationsMutationVariables = Types.Exact<{
  _in?: Types.InputMaybe<Array<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input']>;
}>;


export type ReadNotificationsMutation = { __typename?: 'mutation_root', update_notification_many?: Array<{ __typename?: 'notification_mutation_response', affected_rows: number } | null> | null };

export type InsertDocumentSubTaskMutationVariables = Types.Exact<{
  object: Types.TaskInsertInput;
}>;


export type InsertDocumentSubTaskMutation = { __typename?: 'mutation_root', insert_task_one?: { __typename?: 'task', id: any } | null };

export type ChangeDocumentTaskMutationVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
  _set?: Types.InputMaybe<Types.TaskSetInput>;
}>;


export type ChangeDocumentTaskMutation = { __typename?: 'mutation_root', update_task_by_pk?: { __typename?: 'task', id: any, status: Types.TaskStatusesEnum, indicators?: string | null, reporting_methods?: string | null, reporting_deadline?: any | null } | null };

export type UpdateDocumentStatusMutationVariables = Types.Exact<{
  set?: Types.InputMaybe<Types.DocumentsSetInput>;
  id: Types.Scalars['uuid']['input'];
}>;


export type UpdateDocumentStatusMutation = { __typename?: 'mutation_root', update_documents_by_pk?: { __typename?: 'documents', id: any, title: string, deadline: any, created_at: any, status: Types.TaskStatusesEnum, chapter?: { __typename?: 'chapters', id: number } | null } | null };

export type InsertAnswerIntoDocumentTaskMutationVariables = Types.Exact<{
  object: Types.TaskAnswersInsertInput;
}>;


export type InsertAnswerIntoDocumentTaskMutation = { __typename?: 'mutation_root', insert_task_answers_one?: { __typename?: 'task_answers', id: any, answer: string, created_at: any, integration_phase?: Types.IntegrationPhasesEnumEnum | null, doc_ids?: any | null, task: { __typename?: 'task', document?: { __typename?: 'documents', phase?: string | null } | null }, created_by_user: { __typename?: 'users', id: any, displayName: string } } | null };

export type InsertDocumentMutationVariables = Types.Exact<{
  obj: Types.InsertDocumentDocumentsInsertInput;
}>;


export type InsertDocumentMutation = { __typename?: 'mutation_root', insertDocument?: { __typename?: 'InsertDocumentOutput', id: any, title: string, deadline: any, created_at: any } | null };

export type UpdateChapterPhaseMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  set?: Types.InputMaybe<Types.ChaptersSetInput>;
}>;


export type UpdateChapterPhaseMutation = { __typename?: 'mutation_root', update_chapters_by_pk?: { __typename?: 'chapters', id: number, chapter_title: string, cluster_id?: number | null, phase?: Types.IntegrationPhasesEnumEnum | null, cluster?: { __typename?: 'clusters', title: string, color?: string | null, id: number } | null } | null };

export type UpdateMeetingByPkMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  _set?: Types.InputMaybe<Types.MeetingsSetInput>;
}>;


export type UpdateMeetingByPkMutation = { __typename?: 'mutation_root', update_meetings_by_pk?: { __typename?: 'meetings', id: any, doc_ids?: any | null } | null };

export type UpdateCalendarTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  _set?: Types.InputMaybe<Types.TaskSetInput>;
}>;


export type UpdateCalendarTaskMutation = { __typename?: 'mutation_root', update_task_by_pk?: { __typename?: 'task', id: any, doc_ids?: any | null } | null };

export type UpdateReportTaskMutationVariables = Types.Exact<{
  docId: Types.Scalars['jsonb']['input'];
  taskId: Types.Scalars['uuid']['input'];
}>;


export type UpdateReportTaskMutation = { __typename?: 'mutation_root', update_report_tasks_by_pk?: { __typename?: 'report_tasks', id: any, doc_ids?: any | null } | null };

export type UpdateBookmarkTaskMutationVariables = Types.Exact<{
  docId: Types.Scalars['jsonb']['input'];
  taskId: Types.Scalars['uuid']['input'];
}>;


export type UpdateBookmarkTaskMutation = { __typename?: 'mutation_root', update_bookmark_tasks_by_pk?: { __typename?: 'bookmark_tasks', id: any, doc_ids?: any | null } | null };

export type UpdateAcquisTaskMutationVariables = Types.Exact<{
  docId: Types.Scalars['jsonb']['input'];
  taskId: Types.Scalars['uuid']['input'];
}>;


export type UpdateAcquisTaskMutation = { __typename?: 'mutation_root', update_pkie_measure_by_pk?: { __typename?: 'pkie_measure', id: any, doc_ids?: any | null } | null };

export type UpdateMeetingMutationVariables = Types.Exact<{
  docId: Types.Scalars['jsonb']['input'];
  meetingId: Types.Scalars['uuid']['input'];
}>;


export type UpdateMeetingMutation = { __typename?: 'mutation_root', update_meetings_by_pk?: { __typename?: 'meetings', id: any, doc_ids?: any | null } | null };

export type UpdateGeneralTaskMutationVariables = Types.Exact<{
  docId: Types.Scalars['jsonb']['input'];
  taskId: Types.Scalars['uuid']['input'];
}>;


export type UpdateGeneralTaskMutation = { __typename?: 'mutation_root', update_task_by_pk?: { __typename?: 'task', id: any, doc_ids?: any | null } | null };

export type UpdateBookmarkStatusMutationVariables = Types.Exact<{
  bookmarkId: Types.Scalars['Int']['input'];
  status: Types.BookmarkStatusEnum;
}>;


export type UpdateBookmarkStatusMutation = { __typename?: 'mutation_root', update_bookmarks_by_pk?: { __typename?: 'bookmarks', id: number, status?: Types.BookmarkStatusEnum | null } | null };

export type InsertLgaMutationVariables = Types.Exact<{
  celex: Types.Scalars['String']['input'];
}>;


export type InsertLgaMutation = { __typename?: 'mutation_root', insert_pkie_proces_pune_lga_one?: { __typename?: 'pkie_proces_pune_lga', id: any, celex: string } | null };

export type InsertIagaMutationVariables = Types.Exact<{
  celex: Types.Scalars['String']['input'];
}>;


export type InsertIagaMutation = { __typename?: 'mutation_root', insert_pkie_proces_pune_iaga_one?: { __typename?: 'pkie_proces_pune_iaga', id: any, celex: string } | null };

export type InsertNationalActMutationVariables = Types.Exact<{
  object: Types.PkieNationalActsInsertInput;
}>;


export type InsertNationalActMutation = { __typename?: 'mutation_root', insert_pkie_national_acts_one?: { __typename?: 'pkie_national_acts', eli: string, type?: Types.PkieActTypeEnum | null, title: string, qbz_id?: any | null, publish_date?: any | null, act_number?: string | null, dms_id?: number | null, id: any } | null };

export type UpdateLgaMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  set: Types.PkieProcesPuneLgaSetInput;
}>;


export type UpdateLgaMutation = { __typename?: 'mutation_root', update_pkie_proces_pune_lga_by_pk?: { __typename?: 'pkie_proces_pune_lga', id: any, transpozim_plane?: string | null, harmonizimi_hapesirat?: string | null } | null };

export type UpdateIagaMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  set: Types.PkieProcesPuneIagaSetInput;
}>;


export type UpdateIagaMutation = { __typename?: 'mutation_root', update_pkie_proces_pune_iaga_by_pk?: { __typename?: 'pkie_proces_pune_iaga', id: any, boshlleqet_e_struktures?: string | null, celex: string, elemente_te_struktures?: string | null, ngritja_institucionale?: string | null, pershtatje_struktura_administrative?: string | null, struktura_administrative?: string | null, synimi_aspekte_financiare?: string | null } | null };

export type UpdateReportChapterStatusMutationVariables = Types.Exact<{
  set: Types.UpdateReportChaptersByPkReportChaptersSetInput;
  pk_columns: Types.UpdateReportChaptersByPkReportChaptersPkColumnsInput;
}>;


export type UpdateReportChapterStatusMutation = { __typename?: 'mutation_root', UpdateReportChaptersByPK?: { __typename?: 'UpdateReportChaptersByPKOutput', id: any } | null };

export type InsertRoadmapDocumentMutationVariables = Types.Exact<{
  object: Types.DocumentsInsertInput;
}>;


export type InsertRoadmapDocumentMutation = { __typename?: 'mutation_root', insert_documents_one?: { __typename?: 'documents', id: any } | null };

export type UpdateDocumentByPkMutationVariables = Types.Exact<{
  pk_columns: Types.UpdateDocumentByPkDocumentsPkColumnsInput;
  set: Types.UpdateDocumentByPkDocumentsSetInput;
}>;


export type UpdateDocumentByPkMutation = { __typename?: 'mutation_root', UpdateDocumentByPk?: { __typename?: 'UpdateDocumentByPkOutput', id: any, title: string, deadline: any, created_at?: any | null, status?: Types.UpdateDocumentByPkTaskStatusesEnum | null } | null };

export type UpdateDocumentTasksStatusManyMutationVariables = Types.Exact<{
  where: Types.TaskBoolExp;
  status?: Types.InputMaybe<Types.TaskStatusesEnum>;
}>;


export type UpdateDocumentTasksStatusManyMutation = { __typename?: 'mutation_root', update_task_many?: Array<{ __typename?: 'task_mutation_response', affected_rows: number } | null> | null };

export type UpdateDocumentTasksManyMutationVariables = Types.Exact<{
  where: Types.TaskBoolExp;
  set: Types.TaskSetInput;
}>;


export type UpdateDocumentTasksManyMutation = { __typename?: 'mutation_root', update_task_many?: Array<{ __typename?: 'task_mutation_response', affected_rows: number } | null> | null };

export type UpdateMeetingAttendanceMutationVariables = Types.Exact<{
  where: Types.MeetingUsersBoolExp;
  _set: Types.MeetingUsersSetInput;
}>;


export type UpdateMeetingAttendanceMutation = { __typename?: 'mutation_root', update_meeting_users?: { __typename?: 'meeting_users_mutation_response', affected_rows: number } | null };

export type UpdatePkieAcquisWorkPlanTaskMutationVariables = Types.Exact<{
  object: Array<Types.PkieAcquisWorkPlanTaskUpdates> | Types.PkieAcquisWorkPlanTaskUpdates;
}>;


export type UpdatePkieAcquisWorkPlanTaskMutation = { __typename?: 'mutation_root', update_pkie_acquis_work_plan_task_many?: Array<{ __typename?: 'pkie_acquis_work_plan_task_mutation_response', affected_rows: number } | null> | null };

export type InsertAcquisWorkPlanTaskMutationVariables = Types.Exact<{
  object: Types.PkieAcquisWorkPlanTaskInsertInput;
}>;


export type InsertAcquisWorkPlanTaskMutation = { __typename?: 'mutation_root', insert_pkie_acquis_work_plan_task_one?: { __typename?: 'pkie_acquis_work_plan_task', id: any } | null };

export type InsertEventMutationVariables = Types.Exact<{
  object: Types.EventsInsertInput;
}>;


export type InsertEventMutation = { __typename?: 'mutation_root', insert_events_one?: { __typename?: 'events', id: any } | null };

export type InsertAcquisMutationVariables = Types.Exact<{
  object: Types.InsertAcquisPkieAcquisInsertInput;
}>;


export type InsertAcquisMutation = { __typename?: 'mutation_root', insertAcquis?: { __typename?: 'InsertAcquisOutput', celex: string } | null };

export type UpdateAcquisMutationVariables = Types.Exact<{
  celex: Types.Scalars['String']['input'];
  set?: Types.InputMaybe<Types.PkieAcquisSetInput>;
  objects: Array<Types.PkieAcquisChapterInsertInput> | Types.PkieAcquisChapterInsertInput;
}>;


export type UpdateAcquisMutation = { __typename?: 'mutation_root', update_pkie_acquis_by_pk?: { __typename?: 'pkie_acquis', level_of_alignment?: Types.PkieLevelOfAlignmentEnum | null, is_aligned?: boolean | null, dms_id?: number | null } | null, insert_pkie_acquis_chapter?: { __typename?: 'pkie_acquis_chapter_mutation_response', returning: Array<{ __typename?: 'pkie_acquis_chapter', celex: string }> } | null };

export type InsertErrorLogsMutationVariables = Types.Exact<{
  object: Types.ErrorLogsInsertInput;
}>;


export type InsertErrorLogsMutation = { __typename?: 'mutation_root', insert_error_logs_one?: { __typename?: 'error_logs', id: any } | null };

export type UpdateErrorLogsByPkMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  solved_date: Types.Scalars['timestamptz']['input'];
}>;


export type UpdateErrorLogsByPkMutation = { __typename?: 'mutation_root', update_error_logs_by_pk?: { __typename?: 'error_logs', id: any } | null };

export type DeleteGnpiesMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
}>;


export type DeleteGnpiesMutation = { __typename?: 'mutation_root', delete_gnpie?: { __typename?: 'gnpie_mutation_response', affected_rows: number } | null };

export type UpdateUserRoleMutationVariables = Types.Exact<{
  object: Types.AuthUserRolesSetInput;
  id: Types.Scalars['uuid']['input'];
}>;


export type UpdateUserRoleMutation = { __typename?: 'mutation_root', updateAuthUserRole?: { __typename?: 'authUserRoles', id: any } | null };

export type InsertMeasureBookmarkOneMutationVariables = Types.Exact<{
  object: Types.PkieMeasureBookmarkInsertInput;
}>;


export type InsertMeasureBookmarkOneMutation = { __typename?: 'mutation_root', insert_pkie_measure_bookmark_one?: { __typename?: 'pkie_measure_bookmark', id: any, measure: { __typename?: 'pkie_measure', id: any, acquis_chapter?: { __typename?: 'pkie_acquis_chapter', celex: string } | null } } | null };

export type InsertMeasureRoadmapOneMutationVariables = Types.Exact<{
  object: Types.PkieMeasureRoadmapInsertInput;
}>;


export type InsertMeasureRoadmapOneMutation = { __typename?: 'mutation_root', insert_pkie_measure_roadmap_one?: { __typename?: 'pkie_measure_roadmap', id: any, measure: { __typename?: 'pkie_measure', id: any, acquis_chapter?: { __typename?: 'pkie_acquis_chapter', celex: string } | null } } | null };

export type DeleteMeasureRoadmapMutationVariables = Types.Exact<{
  taskId: Types.Scalars['uuid']['input'];
  measureId: Types.Scalars['uuid']['input'];
}>;


export type DeleteMeasureRoadmapMutation = { __typename?: 'mutation_root', delete_pkie_measure_roadmap?: { __typename?: 'pkie_measure_roadmap_mutation_response', returning: Array<{ __typename?: 'pkie_measure_roadmap', task: { __typename?: 'task', id: any, text?: string | null } }> } | null };

export type InsertTaskGnpieMemberMutationVariables = Types.Exact<{
  object: Types.TaskGnpieMembersInsertInput;
}>;


export type InsertTaskGnpieMemberMutation = { __typename?: 'mutation_root', insert_task_gnpie_members_one?: { __typename?: 'task_gnpie_members', id: any, gnpie_member: { __typename?: 'users', id: any, displayName: string } } | null };

export type DeleteTaskGnpieMemberMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type DeleteTaskGnpieMemberMutation = { __typename?: 'mutation_root', delete_task_gnpie_members_by_pk?: { __typename?: 'task_gnpie_members', id: any } | null };

export type InsertBookmarkTaskGnpieMemberMutationVariables = Types.Exact<{
  object: Types.BookmarkGnpieMembersInsertInput;
}>;


export type InsertBookmarkTaskGnpieMemberMutation = { __typename?: 'mutation_root', insert_bookmark_gnpie_members_one?: { __typename?: 'bookmark_gnpie_members', id: any, gnpie_member: { __typename?: 'users', id: any, displayName: string } } | null };

export type DeleteBookmarkTaskGnpieMemberMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type DeleteBookmarkTaskGnpieMemberMutation = { __typename?: 'mutation_root', delete_bookmark_gnpie_members_by_pk?: { __typename?: 'bookmark_gnpie_members', id: any } | null };

export type InsertReportTaskGnpieMemberMutationVariables = Types.Exact<{
  object: Types.ReportTaskGnpieMembersInsertInput;
}>;


export type InsertReportTaskGnpieMemberMutation = { __typename?: 'mutation_root', insert_report_task_gnpie_members_one?: { __typename?: 'report_task_gnpie_members', id: any, gnpie_member: { __typename?: 'users', id: any, displayName: string } } | null };

export type DeleteReportTaskGnpieMemberMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type DeleteReportTaskGnpieMemberMutation = { __typename?: 'mutation_root', delete_report_task_gnpie_members_by_pk?: { __typename?: 'report_task_gnpie_members', id: any } | null };

export type InsertPkieMeasureGnpieMemberMutationVariables = Types.Exact<{
  object: Types.PkieMeasureGnpieMembersInsertInput;
}>;


export type InsertPkieMeasureGnpieMemberMutation = { __typename?: 'mutation_root', insert_pkie_measure_gnpie_members_one?: { __typename?: 'pkie_measure_gnpie_members', id: any, gnpie_member: { __typename?: 'users', id: any, displayName: string } } | null };

export type DeletePkieMeasureGnpieMemberMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type DeletePkieMeasureGnpieMemberMutation = { __typename?: 'mutation_root', delete_pkie_measure_gnpie_members_by_pk?: { __typename?: 'pkie_measure_gnpie_members', id: any } | null };

export type UpdateNationalActMutationVariables = Types.Exact<{
  eli: Types.Scalars['String']['input'];
  set: Types.PkieNationalActsSetInput;
}>;


export type UpdateNationalActMutation = { __typename?: 'mutation_root', update_pkie_national_acts_by_pk?: { __typename?: 'pkie_national_acts', eli: string, type?: Types.PkieActTypeEnum | null, title: string, qbz_id?: any | null, publish_date?: any | null, act_number?: string | null, dms_id?: number | null, id: any } | null };

export type SubchapterFragment = { __typename?: 'sub_chapters', id: number, title?: string | null, title_en?: string | null, active?: boolean | null, chapter_id: number, sub_chapter_number?: string | null, sub_chapter_lead_institutions: Array<{ __typename?: 'sub_chapter_lead_institutions', id: any, institution: { __typename?: 'institutions', id: any, name: string } }>, topics: Array<{ __typename?: 'topics', id: number, title: string, title_en?: string | null, active?: boolean | null, chapter_id?: number | null, subchapter_id?: number | null }> };

export type UpdateSubChapterMutationVariables = Types.Exact<{
  set: Types.SubChaptersSetInput;
  id: Types.Scalars['Int']['input'];
}>;


export type UpdateSubChapterMutation = { __typename?: 'mutation_root', update_sub_chapters_by_pk?: { __typename?: 'sub_chapters', id: number, title?: string | null, title_en?: string | null, active?: boolean | null, chapter_id: number, sub_chapter_number?: string | null, sub_chapter_lead_institutions: Array<{ __typename?: 'sub_chapter_lead_institutions', id: any, institution: { __typename?: 'institutions', id: any, name: string } }>, topics: Array<{ __typename?: 'topics', id: number, title: string, title_en?: string | null, active?: boolean | null, chapter_id?: number | null, subchapter_id?: number | null }> } | null };

export type UpdateTopicMutationVariables = Types.Exact<{
  set: Types.TopicsSetInput;
  id: Types.Scalars['Int']['input'];
}>;


export type UpdateTopicMutation = { __typename?: 'mutation_root', update_topics_by_pk?: { __typename?: 'topics', id: number, title: string, title_en?: string | null, active?: boolean | null, chapter_id?: number | null, subchapter_id?: number | null } | null };

export type CreateSubchapterMutationVariables = Types.Exact<{
  object: Types.SubChaptersInsertInput;
}>;


export type CreateSubchapterMutation = { __typename?: 'mutation_root', insert_sub_chapters_one?: { __typename?: 'sub_chapters', id: number, title?: string | null, title_en?: string | null, active?: boolean | null, chapter_id: number, sub_chapter_number?: string | null, sub_chapter_lead_institutions: Array<{ __typename?: 'sub_chapter_lead_institutions', id: any, institution: { __typename?: 'institutions', id: any, name: string } }>, topics: Array<{ __typename?: 'topics', id: number, title: string, title_en?: string | null, active?: boolean | null, chapter_id?: number | null, subchapter_id?: number | null }> } | null };

export type InsertTopicMutationVariables = Types.Exact<{
  object: Types.TopicsInsertInput;
}>;


export type InsertTopicMutation = { __typename?: 'mutation_root', insert_topics_one?: { __typename?: 'topics', id: number, title: string, title_en?: string | null, active?: boolean | null, chapter_id?: number | null, subchapter_id?: number | null } | null };

export type DeleteSubchapterMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteSubchapterMutation = { __typename?: 'mutation_root', delete_sub_chapters_by_pk?: { __typename?: 'sub_chapters', id: number } | null };

export type DeleteTopicMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteTopicMutation = { __typename?: 'mutation_root', delete_topics_by_pk?: { __typename?: 'topics', id: number } | null };

export type InsertMeasureAcquisWorkplanTaskMutationVariables = Types.Exact<{
  object: Types.PkieMeasureAcqWplTaskInsertInput;
}>;


export type InsertMeasureAcquisWorkplanTaskMutation = { __typename?: 'mutation_root', insert_pkie_measure_acq_wpl_task_one?: { __typename?: 'pkie_measure_acq_wpl_task', id: any, acquis_work_plan_task: { __typename?: 'pkie_acquis_work_plan_task', id: any }, measure: { __typename?: 'pkie_measure', id: any, acquis_work_plan_task_id?: any | null, text: string, year_of_completition?: number | null, type: Types.PkieTypeMeasureEnum, is_completed?: boolean | null, measure_acq_wpl_tasks: Array<{ __typename?: 'pkie_measure_acq_wpl_task', id: any, measure_id: any, aquis_work_plan_task_id: any }>, acquis_chapter?: { __typename?: 'pkie_acquis_chapter', id: any, celex: string } | null, negotiator?: { __typename?: 'users', id: any, displayName: string } | null, chief_gnpie_secretariat?: { __typename?: 'users', id: any, displayName: string } | null, gnpie_secretariat?: { __typename?: 'users', id: any, displayName: string } | null, created_by_user: { __typename?: 'users', id: any, displayName: string } } } | null };

export type InsertWorkPlanTaskMemberMutationVariables = Types.Exact<{
  object: Types.PkieWorkPlanTaskMemberInsertInput;
}>;


export type InsertWorkPlanTaskMemberMutation = { __typename?: 'mutation_root', insert_pkie_work_plan_task_member_one?: { __typename?: 'pkie_work_plan_task_member', id: any, user: { __typename?: 'users', id: any, displayName: string } } | null };

export type DeleteMeasureAcquisWorkPlanTaskMutationVariables = Types.Exact<{
  measureId: Types.Scalars['uuid']['input'];
  workPlanTaskId?: Types.InputMaybe<Types.Scalars['uuid']['input']>;
  metadata: Types.Scalars['jsonb']['input'];
}>;


export type DeleteMeasureAcquisWorkPlanTaskMutation = { __typename?: 'mutation_root', comments?: { __typename?: 'pkie_measure_acq_wpl_task_comment_mutation_response', returning: Array<{ __typename?: 'pkie_measure_acq_wpl_task_comment', id: any }> } | null, measure?: { __typename?: 'pkie_measure_acq_wpl_task_mutation_response', affected_rows: number } | null };

export type DeleteMemberFromWorkplanTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;


export type DeleteMemberFromWorkplanTaskMutation = { __typename?: 'mutation_root', delete_pkie_work_plan_task_member_by_pk?: { __typename?: 'pkie_work_plan_task_member', id: any } | null };

export type ChangeMeasureWithCommentMutationVariables = Types.Exact<{
  measure_acq_wpl_task_id: Types.Scalars['uuid']['input'];
  comment?: Types.InputMaybe<Types.Scalars['String']['input']>;
  status: Types.PkieMeasureAcqWplTaskStatusEnum;
}>;


export type ChangeMeasureWithCommentMutation = { __typename?: 'mutation_root', update_pkie_measure_acq_wpl_task_by_pk?: { __typename?: 'pkie_measure_acq_wpl_task', id: any } | null, insert_pkie_measure_acq_wpl_task_comment_one?: { __typename?: 'pkie_measure_acq_wpl_task_comment', id: any, comment: string } | null };

export type InsertReportTemplateMutationVariables = Types.Exact<{
  title: Types.Scalars['String']['input'];
}>;


export type InsertReportTemplateMutation = { __typename?: 'mutation_root', insertReportTemplate?: { __typename?: 'TemplateInsertOutput', template_id?: any | null } | null };

export type DeleteCommentsMutationVariables = Types.Exact<{
  measure_acq_wpl_task_id: Types.Scalars['uuid']['input'];
  metadata: Types.Scalars['jsonb']['input'];
}>;


export type DeleteCommentsMutation = { __typename?: 'mutation_root', update_pkie_measure_acq_wpl_task_comment?: { __typename?: 'pkie_measure_acq_wpl_task_comment_mutation_response', returning: Array<{ __typename?: 'pkie_measure_acq_wpl_task_comment', id: any }> } | null };

export const InstitutionFragmentDoc = gql`
    fragment Institution on institutions {
  id
  name
  type
  qbz_id
  short_name
  parent_id
}
    `;
export const SubchapterFragmentDoc = gql`
    fragment Subchapter on sub_chapters {
  id
  title
  title_en
  active
  chapter_id
  sub_chapter_number
  sub_chapter_lead_institutions {
    id
    institution {
      id
      name
    }
  }
  topics(order_by: {title: asc}) {
    id
    title
    title_en
    active
    chapter_id
    subchapter_id
  }
}
    `;
export const InsertProgresReportDocument = gql`
    mutation insertProgresReport($report_name: String!, $deadline: date!, $dms_id: Int!, $template_id: uuid!) {
  insertProgressReport(
    report: {report_name: $report_name, deadline: $deadline, dms_id: $dms_id, template_id: $template_id}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertProgresReportGQL extends Apollo.Mutation<InsertProgresReportMutation, InsertProgresReportMutationVariables> {
    override document = InsertProgresReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertUserDocument = gql`
    mutation insertUser($object: users_insert_input!) {
  insertUser(object: $object) {
    id
    displayName
    email
    dms_id
    phoneNumber
    createdAt
    disabled
    defaultRole
    metadata
    institution {
      id
      type
      name
      parent_id
    }
    roles {
      id
      role
      modules
    }
    gnpies {
      id
      role
      chapter_id
      chapter {
        id
        chapter_number
      }
      is_main_negotiator
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertUserGQL extends Apollo.Mutation<InsertUserMutation, InsertUserMutationVariables> {
    override document = InsertUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation updateUser($id: uuid!, $object: users_set_input!) {
  updateUser(pk_columns: {id: $id}, _set: $object) {
    id
    displayName
    email
    phoneNumber
    defaultRole
    institution {
      id
      name
      type
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    override document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpsertUserDocument = gql`
    mutation upsertUser($id: uuid!, $user: users_set_input!, $gnpies: [gnpie_insert_input!]!) {
  gnpies: insert_gnpie(
    objects: $gnpies
    on_conflict: {constraint: gnpie_pkey, update_columns: [chapter_id, cluster_id, is_main_negotiator, role]}
  ) {
    returning {
      id
      role
      chapter_id
      created_by_role
      chapter {
        id
        chapter_number
      }
      cluster {
        id
        title
      }
      is_main_negotiator
    }
  }
  user: updateUser(pk_columns: {id: $id}, _set: $user) {
    displayName
    email
    id
    dms_id
    phoneNumber
    createdAt
    disabled
    defaultRole
    metadata
    institution {
      id
      type
      name
      parent_id
    }
    roles {
      id
      role
      modules
    }
    gnpies {
      id
      role
      chapter_id
      created_by_role
      cluster {
        id
        title
      }
      chapter {
        id
        chapter_number
      }
      is_main_negotiator
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpsertUserGQL extends Apollo.Mutation<UpsertUserMutation, UpsertUserMutationVariables> {
    override document = UpsertUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteGnpieDocument = gql`
    mutation deleteGnpie($id: uuid!) {
  delete_gnpie_by_pk(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteGnpieGQL extends Apollo.Mutation<DeleteGnpieMutation, DeleteGnpieMutationVariables> {
    override document = DeleteGnpieDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpsertChapterInstitutionsDocument = gql`
    mutation upsertChapterInstitutions($chapter_id: Int!, $objects: [chapter_lead_institutions_insert_input!]!) {
  delete: delete_chapter_lead_institutions(
    where: {chapter_id: {_eq: $chapter_id}}
  ) {
    affected_rows
  }
  insert: insert_chapter_lead_institutions(objects: $objects) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpsertChapterInstitutionsGQL extends Apollo.Mutation<UpsertChapterInstitutionsMutation, UpsertChapterInstitutionsMutationVariables> {
    override document = UpsertChapterInstitutionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpsertSubChapterInstitutionsDocument = gql`
    mutation upsertSubChapterInstitutions($sub_chapter_id: Int!, $objects: [sub_chapter_lead_institutions_insert_input!]!) {
  delete: delete_sub_chapter_lead_institutions(
    where: {sub_chapter_id: {_eq: $sub_chapter_id}}
  ) {
    affected_rows
  }
  insert: insert_sub_chapter_lead_institutions(objects: $objects) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpsertSubChapterInstitutionsGQL extends Apollo.Mutation<UpsertSubChapterInstitutionsMutation, UpsertSubChapterInstitutionsMutationVariables> {
    override document = UpsertSubChapterInstitutionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertNewInstitutionDocument = gql`
    mutation insertNewInstitution($object: institutions_insert_input!) {
  insert_institutions_one(object: $object) {
    ...Institution
  }
}
    ${InstitutionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertNewInstitutionGQL extends Apollo.Mutation<InsertNewInstitutionMutation, InsertNewInstitutionMutationVariables> {
    override document = InsertNewInstitutionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateInstitutionDocument = gql`
    mutation updateInstitution($id: uuid!, $object: institutions_set_input!) {
  update_institutions_by_pk(pk_columns: {id: $id}, _set: $object) {
    ...Institution
  }
}
    ${InstitutionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateInstitutionGQL extends Apollo.Mutation<UpdateInstitutionMutation, UpdateInstitutionMutationVariables> {
    override document = UpdateInstitutionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateManyReportTasksDocument = gql`
    mutation updateManyReportTasks($set: report_tasks_set_input!, $where: report_tasks_bool_exp!) {
  update_report_tasks_many(updates: {_set: $set, where: $where}) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateManyReportTasksGQL extends Apollo.Mutation<UpdateManyReportTasksMutation, UpdateManyReportTasksMutationVariables> {
    override document = UpdateManyReportTasksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProgressReportDocument = gql`
    mutation updateProgressReport($set: reports_set_input, $id: uuid!) {
  update_reports_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProgressReportGQL extends Apollo.Mutation<UpdateProgressReportMutation, UpdateProgressReportMutationVariables> {
    override document = UpdateProgressReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertRecommendationDocument = gql`
    mutation insertRecommendation($object: recommendations_insert_input!) {
  insert_recommendations_one(object: $object) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertRecommendationGQL extends Apollo.Mutation<InsertRecommendationMutation, InsertRecommendationMutationVariables> {
    override document = InsertRecommendationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertBookmarkDocument = gql`
    mutation insertBookmark($object: bookmarks_insert_input!) {
  insert_bookmarks_one(object: $object) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertBookmarkGQL extends Apollo.Mutation<InsertBookmarkMutation, InsertBookmarkMutationVariables> {
    override document = InsertBookmarkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditQuestionDocument = gql`
    mutation editQuestion($set: questions_set_input!, $id: uuid!) {
  update_questions_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
    text
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditQuestionGQL extends Apollo.Mutation<EditQuestionMutation, EditQuestionMutationVariables> {
    override document = EditQuestionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateQuestionsStatusesDocument = gql`
    mutation updateQuestionsStatuses($ids: [uuid!]!, $status: Boolean!) {
  update_questions(where: {id: {_in: $ids}}, _set: {is_active: $status}) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateQuestionsStatusesGQL extends Apollo.Mutation<UpdateQuestionsStatusesMutation, UpdateQuestionsStatusesMutationVariables> {
    override document = UpdateQuestionsStatusesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBookmarkDocument = gql`
    mutation updateBookmark($bookmarkId: Int!, $object: bookmarks_set_input!) {
  update_bookmarks_by_pk(pk_columns: {id: $bookmarkId}, _set: $object) {
    id
    code_id
    title
    deadline
    reference
    is_completed
    created_by_user_id
    bookmark_tasks {
      id
      status
    }
    recommendation {
      id
      title
    }
    assignee {
      id
      displayName
    }
    assigned_to_user_id
    chapter_id
    chapter {
      id
      chapter_title
      chapter_number
    }
    sub_chapter_id
    sub_chapter {
      id
      title
      sub_chapter_number
    }
    topic_id
    topic {
      id
      title
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBookmarkGQL extends Apollo.Mutation<UpdateBookmarkMutation, UpdateBookmarkMutationVariables> {
    override document = UpdateBookmarkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertQuestionDocument = gql`
    mutation insertQuestion($object: questions_insert_input!) {
  insert_questions_one(object: $object) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertQuestionGQL extends Apollo.Mutation<InsertQuestionMutation, InsertQuestionMutationVariables> {
    override document = InsertQuestionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertBookmarkTaskDocument = gql`
    mutation insertBookmarkTask($object: bookmark_tasks_insert_input!) {
  insert_bookmark_tasks_one(object: $object) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertBookmarkTaskGQL extends Apollo.Mutation<InsertBookmarkTaskMutation, InsertBookmarkTaskMutationVariables> {
    override document = InsertBookmarkTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertCommentIntoTaskDocument = gql`
    mutation insertCommentIntoTask($object: report_task_comment_insert_input!) {
  insert_report_task_comment_one(object: $object) {
    id
    comment
    created_by_user {
      id
      displayName
    }
    created_at
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertCommentIntoTaskGQL extends Apollo.Mutation<InsertCommentIntoTaskMutation, InsertCommentIntoTaskMutationVariables> {
    override document = InsertCommentIntoTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertCommentIntoBookmarkTaskDocument = gql`
    mutation insertCommentIntoBookmarkTask($object: bookmark_task_comment_insert_input!) {
  insert_bookmark_task_comment_one(object: $object) {
    id
    comment
    created_at
    created_by_user {
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertCommentIntoBookmarkTaskGQL extends Apollo.Mutation<InsertCommentIntoBookmarkTaskMutation, InsertCommentIntoBookmarkTaskMutationVariables> {
    override document = InsertCommentIntoBookmarkTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertAnswerIntoBookmarkTaskDocument = gql`
    mutation insertAnswerIntoBookmarkTask($object: bookmark_task_answers_insert_input!) {
  insert_bookmark_task_answers_one(object: $object) {
    id
    answer
    created_at
    created_by_user {
      id
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertAnswerIntoBookmarkTaskGQL extends Apollo.Mutation<InsertAnswerIntoBookmarkTaskMutation, InsertAnswerIntoBookmarkTaskMutationVariables> {
    override document = InsertAnswerIntoBookmarkTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertAnswerIntoReportTaskDocument = gql`
    mutation insertAnswerIntoReportTask($object: report_answers_insert_input!) {
  insert_report_answers_one(object: $object) {
    id
    answer
    created_at
    has_update
    doc_ids
    user {
      id
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertAnswerIntoReportTaskGQL extends Apollo.Mutation<InsertAnswerIntoReportTaskMutation, InsertAnswerIntoReportTaskMutationVariables> {
    override document = InsertAnswerIntoReportTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateReportTaskStatusDocument = gql`
    mutation updateReportTaskStatus($taskId: uuid!, $taskStatus: report_task_statuses_enum!) {
  update_report_tasks_by_pk(
    pk_columns: {id: $taskId}
    _set: {status: $taskStatus}
  ) {
    id
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateReportTaskStatusGQL extends Apollo.Mutation<UpdateReportTaskStatusMutation, UpdateReportTaskStatusMutationVariables> {
    override document = UpdateReportTaskStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBookmarkTaskStatusDocument = gql`
    mutation updateBookmarkTaskStatus($taskId: uuid!, $taskStatus: bookmark_task_statuses_enum!) {
  update_bookmark_tasks_by_pk(
    pk_columns: {id: $taskId}
    _set: {status: $taskStatus}
  ) {
    id
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBookmarkTaskStatusGQL extends Apollo.Mutation<UpdateBookmarkTaskStatusMutation, UpdateBookmarkTaskStatusMutationVariables> {
    override document = UpdateBookmarkTaskStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetFinalAnswerOfTaskDocument = gql`
    mutation setFinalAnswerOfTask($taskId: uuid!, $answerId: uuid!) {
  update_report_tasks_by_pk(
    pk_columns: {id: $taskId}
    _set: {final_answer_id: $answerId}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetFinalAnswerOfTaskGQL extends Apollo.Mutation<SetFinalAnswerOfTaskMutation, SetFinalAnswerOfTaskMutationVariables> {
    override document = SetFinalAnswerOfTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangeAssigneeInReportTaskDocument = gql`
    mutation changeAssigneeInReportTask($taskId: uuid!, $_set: report_tasks_set_input) {
  update_report_tasks_by_pk(pk_columns: {id: $taskId}, _set: $_set) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeAssigneeInReportTaskGQL extends Apollo.Mutation<ChangeAssigneeInReportTaskMutation, ChangeAssigneeInReportTaskMutationVariables> {
    override document = ChangeAssigneeInReportTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangeAssigneeInBookmarkTaskDocument = gql`
    mutation changeAssigneeInBookmarkTask($taskId: uuid!, $_set: bookmark_tasks_set_input) {
  update_bookmark_tasks_by_pk(pk_columns: {id: $taskId}, _set: $_set) {
    id
    doc_ids
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeAssigneeInBookmarkTaskGQL extends Apollo.Mutation<ChangeAssigneeInBookmarkTaskMutation, ChangeAssigneeInBookmarkTaskMutationVariables> {
    override document = ChangeAssigneeInBookmarkTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertAcquisWorkPlanDocument = gql`
    mutation insertAcquisWorkPlan($obj: CreateAcquisWorkPlanPkieWorkPlansInsertInput!) {
  insertAcquisWorkPlan(obj: $obj) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertAcquisWorkPlanGQL extends Apollo.Mutation<InsertAcquisWorkPlanMutation, InsertAcquisWorkPlanMutationVariables> {
    override document = InsertAcquisWorkPlanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertCommentIntoWorkPlanTaskDocument = gql`
    mutation insertCommentIntoWorkPlanTask($object: pkie_work_plan_comment_insert_input!) {
  insert_pkie_work_plan_comment_one(object: $object) {
    id
    comment
    created_at
    created_by_user {
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertCommentIntoWorkPlanTaskGQL extends Apollo.Mutation<InsertCommentIntoWorkPlanTaskMutation, InsertCommentIntoWorkPlanTaskMutationVariables> {
    override document = InsertCommentIntoWorkPlanTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateWorkPlanTaskDocument = gql`
    mutation updateWorkPlanTask($id: uuid!, $set: pkie_work_plan_task_set_input) {
  update_pkie_work_plan_task_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateWorkPlanTaskGQL extends Apollo.Mutation<UpdateWorkPlanTaskMutation, UpdateWorkPlanTaskMutationVariables> {
    override document = UpdateWorkPlanTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateWorkPlanDocument = gql`
    mutation updateWorkPlan($set: pkie_work_plans_set_input, $id: uuid!) {
  update_pkie_work_plans_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateWorkPlanGQL extends Apollo.Mutation<UpdateWorkPlanMutation, UpdateWorkPlanMutationVariables> {
    override document = UpdateWorkPlanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertMeasureDocument = gql`
    mutation insertMeasure($object: pkie_measure_insert_input!) {
  insert_pkie_measure_one(object: $object) {
    id
    acquis_work_plan_task_id
    acquis_chapter {
      id
      celex
    }
    text
    year_of_completition
    type
    is_completed
    negotiator {
      id
      displayName
    }
    chief_gnpie_secretariat {
      id
      displayName
    }
    gnpie_secretariat {
      id
      displayName
    }
    created_by_user {
      id
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertMeasureGQL extends Apollo.Mutation<InsertMeasureMutation, InsertMeasureMutationVariables> {
    override document = InsertMeasureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAcquisByPkDocument = gql`
    mutation updateAcquisByPk($celex: String!, $set: pkie_acquis_set_input) {
  update_pkie_acquis_by_pk(pk_columns: {celex: $celex}, _set: $set) {
    level_of_alignment
    is_aligned
    dms_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAcquisByPkGQL extends Apollo.Mutation<UpdateAcquisByPkMutation, UpdateAcquisByPkMutationVariables> {
    override document = UpdateAcquisByPkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertAcquisInstitutionDocument = gql`
    mutation insertAcquisInstitution($objects: [pkie_acquis_institutions_insert_input!]!) {
  insert_pkie_acquis_institutions(
    objects: $objects
    on_conflict: {constraint: acquis_institutions_institution_id_acqui_celex_key, update_columns: []}
  ) {
    returning {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertAcquisInstitutionGQL extends Apollo.Mutation<InsertAcquisInstitutionMutation, InsertAcquisInstitutionMutationVariables> {
    override document = InsertAcquisInstitutionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAcquisInstitutionByPkDocument = gql`
    mutation deleteAcquisInstitutionByPk($id: uuid!) {
  delete_pkie_acquis_institutions_by_pk(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAcquisInstitutionByPkGQL extends Apollo.Mutation<DeleteAcquisInstitutionByPkMutation, DeleteAcquisInstitutionByPkMutationVariables> {
    override document = DeleteAcquisInstitutionByPkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAcquisTaskByPkDocument = gql`
    mutation updateAcquisTaskByPk($id: uuid!, $set: pkie_measure_set_input) {
  update_pkie_measure_by_pk(pk_columns: {id: $id}, _set: $set) {
    level_of_alignment
    type_of_alignment
    impact
    doc_ids
    national_act {
      eli
      type
      title
      qbz_id
      publish_date
    }
    id
    acquis_work_plan_task_id
    acquis_chapter {
      id
      celex
    }
    text
    proposing_institution {
      id
      name
    }
    quadrennial_scheduled_approval
    weight_transposition
    year_of_completition
    type
    is_completed
    negotiator {
      id
      displayName
    }
    chief_gnpie_secretariat {
      id
      displayName
    }
    gnpie_secretariat {
      id
      displayName
    }
    created_by_user {
      id
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAcquisTaskByPkGQL extends Apollo.Mutation<UpdateAcquisTaskByPkMutation, UpdateAcquisTaskByPkMutationVariables> {
    override document = UpdateAcquisTaskByPkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetActToAcquisDocument = gql`
    mutation setActToAcquis($celex: String!, $eli: String) {
  insert_pkie_acquis_national_acts_one(
    object: {acquis_celex: $celex, national_act_eli: $eli}
  ) {
    id
    national_act {
      acquis_national_acts {
        id
      }
      id
      qbz_id
      title
      eli
      act_number
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetActToAcquisGQL extends Apollo.Mutation<SetActToAcquisMutation, SetActToAcquisMutationVariables> {
    override document = SetActToAcquisDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePkieAcquisNationalActsByPkDocument = gql`
    mutation deletePkieAcquisNationalActsByPk($id: uuid!) {
  delete_pkie_acquis_national_acts_by_pk(id: $id) {
    national_act_eli
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePkieAcquisNationalActsByPkGQL extends Apollo.Mutation<DeletePkieAcquisNationalActsByPkMutation, DeletePkieAcquisNationalActsByPkMutationVariables> {
    override document = DeletePkieAcquisNationalActsByPkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertCommentIntoAcquisTaskDocument = gql`
    mutation insertCommentIntoAcquisTask($object: pkie_measure_comment_insert_input!) {
  insert_pkie_measure_comment_one(object: $object) {
    id
    comment
    created_at
    created_by_user {
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertCommentIntoAcquisTaskGQL extends Apollo.Mutation<InsertCommentIntoAcquisTaskMutation, InsertCommentIntoAcquisTaskMutationVariables> {
    override document = InsertCommentIntoAcquisTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertAnswerIntoAcquisTaskDocument = gql`
    mutation insertAnswerIntoAcquisTask($object: pkie_measure_answers_insert_input!, $measureId: uuid!, $status: pkie_measure_status_enum, $isCompleted: Boolean) {
  insert_pkie_measure_answers_one(object: $object) {
    id
    answer
    doc_ids
    created_at
    status
    created_by_user {
      displayName
    }
    measure {
      id
      is_completed
    }
  }
  update_pkie_measure_by_pk(
    pk_columns: {id: $measureId}
    _set: {status: $status, is_completed: $isCompleted}
  ) {
    id
    status
    is_completed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertAnswerIntoAcquisTaskGQL extends Apollo.Mutation<InsertAnswerIntoAcquisTaskMutation, InsertAnswerIntoAcquisTaskMutationVariables> {
    override document = InsertAnswerIntoAcquisTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertMeasureBudgetDocument = gql`
    mutation insertMeasureBudget($object: pkie_measure_budgets_insert_input!) {
  insert_pkie_measure_budgets_one(object: $object) {
    id
    type_of_budget
    value
    year
    source_of_funding
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertMeasureBudgetGQL extends Apollo.Mutation<InsertMeasureBudgetMutation, InsertMeasureBudgetMutationVariables> {
    override document = InsertMeasureBudgetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertMeasureBookmarksDocument = gql`
    mutation insertMeasureBookmarks($objects: [pkie_measure_bookmark_insert_input!]!) {
  insert_pkie_measure_bookmark(
    objects: $objects
    on_conflict: {constraint: measure_bookmark_pkey, update_columns: []}
  ) {
    returning {
      bookmark {
        id
        title
        deadline
        reference
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertMeasureBookmarksGQL extends Apollo.Mutation<InsertMeasureBookmarksMutation, InsertMeasureBookmarksMutationVariables> {
    override document = InsertMeasureBookmarksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteMeasureBookmarkDocument = gql`
    mutation deleteMeasureBookmark($bookmarkId: Int!, $measureId: uuid!) {
  delete_pkie_measure_bookmark(
    where: {bookmark_id: {_eq: $bookmarkId}, measure_id: {_eq: $measureId}}
  ) {
    returning {
      bookmark {
        id
        title
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteMeasureBookmarkGQL extends Apollo.Mutation<DeleteMeasureBookmarkMutation, DeleteMeasureBookmarkMutationVariables> {
    override document = DeleteMeasureBookmarkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMeasureBudgetByPkDocument = gql`
    mutation updateMeasureBudgetByPk($id: uuid!) {
  update_pkie_measure_budgets_by_pk(
    pk_columns: {id: $id}
    _set: {is_deleted: true}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMeasureBudgetByPkGQL extends Apollo.Mutation<UpdateMeasureBudgetByPkMutation, UpdateMeasureBudgetByPkMutationVariables> {
    override document = UpdateMeasureBudgetByPkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertMeetingDocument = gql`
    mutation insertMeeting($object: meetings_insert_input!) {
  insert_meetings_one(object: $object) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertMeetingGQL extends Apollo.Mutation<InsertMeetingMutation, InsertMeetingMutationVariables> {
    override document = InsertMeetingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertNoteIntoMeetingDocument = gql`
    mutation insertNoteIntoMeeting($object: meeting_notes_insert_input!) {
  insert_meeting_notes_one(object: $object) {
    id
    note
    user {
      id
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertNoteIntoMeetingGQL extends Apollo.Mutation<InsertNoteIntoMeetingMutation, InsertNoteIntoMeetingMutationVariables> {
    override document = InsertNoteIntoMeetingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertNoteIntoEventDocument = gql`
    mutation insertNoteIntoEvent($object: event_notes_insert_input!) {
  insert_event_notes_one(object: $object) {
    id
    note
    user {
      id
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertNoteIntoEventGQL extends Apollo.Mutation<InsertNoteIntoEventMutation, InsertNoteIntoEventMutationVariables> {
    override document = InsertNoteIntoEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertGeneralTaskDocument = gql`
    mutation insertGeneralTask($object: task_insert_input!) {
  insert_task_one(object: $object) {
    id
    text
    deadline
    assignee {
      id
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertGeneralTaskGQL extends Apollo.Mutation<InsertGeneralTaskMutation, InsertGeneralTaskMutationVariables> {
    override document = InsertGeneralTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertAnswerIntoGeneralTaskDocument = gql`
    mutation insertAnswerIntoGeneralTask($object: task_answers_insert_input!) {
  insert_task_answers_one(object: $object) {
    id
    answer
    created_at
    created_by_user {
      id
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertAnswerIntoGeneralTaskGQL extends Apollo.Mutation<InsertAnswerIntoGeneralTaskMutation, InsertAnswerIntoGeneralTaskMutationVariables> {
    override document = InsertAnswerIntoGeneralTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGeneralTaskStatusDocument = gql`
    mutation updateGeneralTaskStatus($id: uuid!, $status: task_statuses_enum!) {
  update_task_by_pk(pk_columns: {id: $id}, _set: {status: $status}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGeneralTaskStatusGQL extends Apollo.Mutation<UpdateGeneralTaskStatusMutation, UpdateGeneralTaskStatusMutationVariables> {
    override document = UpdateGeneralTaskStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertCommentIntoGeneralTaskDocument = gql`
    mutation insertCommentIntoGeneralTask($object: task_comments_insert_input!) {
  insert_task_comments_one(object: $object) {
    id
    comment
    created_by_user {
      id
      displayName
    }
    created_at
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertCommentIntoGeneralTaskGQL extends Apollo.Mutation<InsertCommentIntoGeneralTaskMutation, InsertCommentIntoGeneralTaskMutationVariables> {
    override document = InsertCommentIntoGeneralTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateReportTasksStatusManyDocument = gql`
    mutation updateReportTasksStatusMany($set: report_tasks_set_input!, $where: report_tasks_bool_exp!) {
  update_report_tasks_many(updates: {where: $where, _set: $set}) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateReportTasksStatusManyGQL extends Apollo.Mutation<UpdateReportTasksStatusManyMutation, UpdateReportTasksStatusManyMutationVariables> {
    override document = UpdateReportTasksStatusManyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompleteReportSignatureDocument = gql`
    mutation completeReportSignature($chapterId: Int!, $reportId: uuid!, $code: String!) {
  completeReportSigniture(
    obj: {chapterId: $chapterId, progressReportId: $reportId, code: $code}
  ) {
    message
    success
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompleteReportSignatureGQL extends Apollo.Mutation<CompleteReportSignatureMutation, CompleteReportSignatureMutationVariables> {
    override document = CompleteReportSignatureDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReadNotificationsDocument = gql`
    mutation readNotifications($_in: [uuid!]) {
  update_notification_many(
    updates: {where: {id: {_in: $_in}}, _set: {read: true}}
  ) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReadNotificationsGQL extends Apollo.Mutation<ReadNotificationsMutation, ReadNotificationsMutationVariables> {
    override document = ReadNotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertDocumentSubTaskDocument = gql`
    mutation insertDocumentSubTask($object: task_insert_input!) {
  insert_task_one(object: $object) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertDocumentSubTaskGQL extends Apollo.Mutation<InsertDocumentSubTaskMutation, InsertDocumentSubTaskMutationVariables> {
    override document = InsertDocumentSubTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangeDocumentTaskDocument = gql`
    mutation changeDocumentTask($taskId: uuid!, $_set: task_set_input) {
  update_task_by_pk(pk_columns: {id: $taskId}, _set: $_set) {
    id
    status
    indicators
    reporting_methods
    reporting_deadline
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeDocumentTaskGQL extends Apollo.Mutation<ChangeDocumentTaskMutation, ChangeDocumentTaskMutationVariables> {
    override document = ChangeDocumentTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDocumentStatusDocument = gql`
    mutation updateDocumentStatus($set: documents_set_input, $id: uuid!) {
  update_documents_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
    title
    deadline
    created_at
    status
    chapter {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDocumentStatusGQL extends Apollo.Mutation<UpdateDocumentStatusMutation, UpdateDocumentStatusMutationVariables> {
    override document = UpdateDocumentStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertAnswerIntoDocumentTaskDocument = gql`
    mutation insertAnswerIntoDocumentTask($object: task_answers_insert_input!) {
  insert_task_answers_one(object: $object) {
    id
    answer
    created_at
    integration_phase
    doc_ids
    task {
      document {
        phase
      }
    }
    created_by_user {
      id
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertAnswerIntoDocumentTaskGQL extends Apollo.Mutation<InsertAnswerIntoDocumentTaskMutation, InsertAnswerIntoDocumentTaskMutationVariables> {
    override document = InsertAnswerIntoDocumentTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertDocumentDocument = gql`
    mutation insertDocument($obj: InsertDocumentDocumentsInsertInput!) {
  insertDocument(obj: $obj) {
    id
    title
    deadline
    created_at
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertDocumentGQL extends Apollo.Mutation<InsertDocumentMutation, InsertDocumentMutationVariables> {
    override document = InsertDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateChapterPhaseDocument = gql`
    mutation updateChapterPhase($id: Int!, $set: chapters_set_input) {
  update_chapters_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
    chapter_title
    cluster_id
    phase
    cluster {
      title
      color
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateChapterPhaseGQL extends Apollo.Mutation<UpdateChapterPhaseMutation, UpdateChapterPhaseMutationVariables> {
    override document = UpdateChapterPhaseDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMeetingByPkDocument = gql`
    mutation updateMeetingByPk($id: uuid!, $_set: meetings_set_input) {
  update_meetings_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
    doc_ids
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMeetingByPkGQL extends Apollo.Mutation<UpdateMeetingByPkMutation, UpdateMeetingByPkMutationVariables> {
    override document = UpdateMeetingByPkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCalendarTaskDocument = gql`
    mutation updateCalendarTask($id: uuid!, $_set: task_set_input) {
  update_task_by_pk(pk_columns: {id: $id}, _set: $_set) {
    id
    doc_ids
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCalendarTaskGQL extends Apollo.Mutation<UpdateCalendarTaskMutation, UpdateCalendarTaskMutationVariables> {
    override document = UpdateCalendarTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateReportTaskDocument = gql`
    mutation updateReportTask($docId: jsonb!, $taskId: uuid!) {
  update_report_tasks_by_pk(_append: {doc_ids: $docId}, pk_columns: {id: $taskId}) {
    id
    doc_ids
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateReportTaskGQL extends Apollo.Mutation<UpdateReportTaskMutation, UpdateReportTaskMutationVariables> {
    override document = UpdateReportTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBookmarkTaskDocument = gql`
    mutation updateBookmarkTask($docId: jsonb!, $taskId: uuid!) {
  update_bookmark_tasks_by_pk(
    _append: {doc_ids: $docId}
    pk_columns: {id: $taskId}
  ) {
    id
    doc_ids
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBookmarkTaskGQL extends Apollo.Mutation<UpdateBookmarkTaskMutation, UpdateBookmarkTaskMutationVariables> {
    override document = UpdateBookmarkTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAcquisTaskDocument = gql`
    mutation updateAcquisTask($docId: jsonb!, $taskId: uuid!) {
  update_pkie_measure_by_pk(_append: {doc_ids: $docId}, pk_columns: {id: $taskId}) {
    id
    doc_ids
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAcquisTaskGQL extends Apollo.Mutation<UpdateAcquisTaskMutation, UpdateAcquisTaskMutationVariables> {
    override document = UpdateAcquisTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMeetingDocument = gql`
    mutation updateMeeting($docId: jsonb!, $meetingId: uuid!) {
  update_meetings_by_pk(_append: {doc_ids: $docId}, pk_columns: {id: $meetingId}) {
    id
    doc_ids
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMeetingGQL extends Apollo.Mutation<UpdateMeetingMutation, UpdateMeetingMutationVariables> {
    override document = UpdateMeetingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGeneralTaskDocument = gql`
    mutation updateGeneralTask($docId: jsonb!, $taskId: uuid!) {
  update_task_by_pk(_append: {doc_ids: $docId}, pk_columns: {id: $taskId}) {
    id
    doc_ids
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGeneralTaskGQL extends Apollo.Mutation<UpdateGeneralTaskMutation, UpdateGeneralTaskMutationVariables> {
    override document = UpdateGeneralTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBookmarkStatusDocument = gql`
    mutation updateBookmarkStatus($bookmarkId: Int!, $status: bookmark_status_enum!) {
  update_bookmarks_by_pk(pk_columns: {id: $bookmarkId}, _set: {status: $status}) {
    id
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBookmarkStatusGQL extends Apollo.Mutation<UpdateBookmarkStatusMutation, UpdateBookmarkStatusMutationVariables> {
    override document = UpdateBookmarkStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertLgaDocument = gql`
    mutation insertLga($celex: String!) {
  insert_pkie_proces_pune_lga_one(object: {celex: $celex}) {
    id
    celex
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertLgaGQL extends Apollo.Mutation<InsertLgaMutation, InsertLgaMutationVariables> {
    override document = InsertLgaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertIagaDocument = gql`
    mutation insertIaga($celex: String!) {
  insert_pkie_proces_pune_iaga_one(object: {celex: $celex}) {
    id
    celex
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertIagaGQL extends Apollo.Mutation<InsertIagaMutation, InsertIagaMutationVariables> {
    override document = InsertIagaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertNationalActDocument = gql`
    mutation insertNationalAct($object: pkie_national_acts_insert_input!) {
  insert_pkie_national_acts_one(
    object: $object
    on_conflict: {constraint: national_acts_pkey, update_columns: []}
  ) {
    eli
    type
    title
    qbz_id
    publish_date
    act_number
    dms_id
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertNationalActGQL extends Apollo.Mutation<InsertNationalActMutation, InsertNationalActMutationVariables> {
    override document = InsertNationalActDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateLgaDocument = gql`
    mutation updateLga($id: uuid!, $set: pkie_proces_pune_lga_set_input!) {
  update_pkie_proces_pune_lga_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
    transpozim_plane
    harmonizimi_hapesirat
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateLgaGQL extends Apollo.Mutation<UpdateLgaMutation, UpdateLgaMutationVariables> {
    override document = UpdateLgaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateIagaDocument = gql`
    mutation updateIaga($id: uuid!, $set: pkie_proces_pune_iaga_set_input!) {
  update_pkie_proces_pune_iaga_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
    boshlleqet_e_struktures
    celex
    elemente_te_struktures
    ngritja_institucionale
    pershtatje_struktura_administrative
    struktura_administrative
    synimi_aspekte_financiare
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateIagaGQL extends Apollo.Mutation<UpdateIagaMutation, UpdateIagaMutationVariables> {
    override document = UpdateIagaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateReportChapterStatusDocument = gql`
    mutation updateReportChapterStatus($set: UpdateReportChaptersByPKReportChaptersSetInput!, $pk_columns: UpdateReportChaptersByPKReportChaptersPkColumnsInput!) {
  UpdateReportChaptersByPK(set: $set, pk_columns: $pk_columns) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateReportChapterStatusGQL extends Apollo.Mutation<UpdateReportChapterStatusMutation, UpdateReportChapterStatusMutationVariables> {
    override document = UpdateReportChapterStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertRoadmapDocumentDocument = gql`
    mutation insertRoadmapDocument($object: documents_insert_input!) {
  insert_documents_one(object: $object) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertRoadmapDocumentGQL extends Apollo.Mutation<InsertRoadmapDocumentMutation, InsertRoadmapDocumentMutationVariables> {
    override document = InsertRoadmapDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDocumentByPkDocument = gql`
    mutation updateDocumentByPk($pk_columns: UpdateDocumentByPkDocumentsPkColumnsInput!, $set: UpdateDocumentByPkDocumentsSetInput!) {
  UpdateDocumentByPk(pk_columns: $pk_columns, set: $set) {
    id
    title
    deadline
    created_at
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDocumentByPkGQL extends Apollo.Mutation<UpdateDocumentByPkMutation, UpdateDocumentByPkMutationVariables> {
    override document = UpdateDocumentByPkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDocumentTasksStatusManyDocument = gql`
    mutation updateDocumentTasksStatusMany($where: task_bool_exp!, $status: task_statuses_enum) {
  update_task_many(updates: {where: $where, _set: {status: $status}}) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDocumentTasksStatusManyGQL extends Apollo.Mutation<UpdateDocumentTasksStatusManyMutation, UpdateDocumentTasksStatusManyMutationVariables> {
    override document = UpdateDocumentTasksStatusManyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDocumentTasksManyDocument = gql`
    mutation updateDocumentTasksMany($where: task_bool_exp!, $set: task_set_input!) {
  update_task_many(updates: {where: $where, _set: $set}) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDocumentTasksManyGQL extends Apollo.Mutation<UpdateDocumentTasksManyMutation, UpdateDocumentTasksManyMutationVariables> {
    override document = UpdateDocumentTasksManyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMeetingAttendanceDocument = gql`
    mutation updateMeetingAttendance($where: meeting_users_bool_exp!, $_set: meeting_users_set_input!) {
  update_meeting_users(_set: $_set, where: $where) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMeetingAttendanceGQL extends Apollo.Mutation<UpdateMeetingAttendanceMutation, UpdateMeetingAttendanceMutationVariables> {
    override document = UpdateMeetingAttendanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePkieAcquisWorkPlanTaskDocument = gql`
    mutation updatePkieAcquisWorkPlanTask($object: [pkie_acquis_work_plan_task_updates!]!) {
  update_pkie_acquis_work_plan_task_many(updates: $object) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePkieAcquisWorkPlanTaskGQL extends Apollo.Mutation<UpdatePkieAcquisWorkPlanTaskMutation, UpdatePkieAcquisWorkPlanTaskMutationVariables> {
    override document = UpdatePkieAcquisWorkPlanTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertAcquisWorkPlanTaskDocument = gql`
    mutation insertAcquisWorkPlanTask($object: pkie_acquis_work_plan_task_insert_input!) {
  insert_pkie_acquis_work_plan_task_one(object: $object) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertAcquisWorkPlanTaskGQL extends Apollo.Mutation<InsertAcquisWorkPlanTaskMutation, InsertAcquisWorkPlanTaskMutationVariables> {
    override document = InsertAcquisWorkPlanTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertEventDocument = gql`
    mutation insertEvent($object: events_insert_input!) {
  insert_events_one(object: $object) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertEventGQL extends Apollo.Mutation<InsertEventMutation, InsertEventMutationVariables> {
    override document = InsertEventDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertAcquisDocument = gql`
    mutation insertAcquis($object: InsertAcquisPkieAcquisInsertInput!) {
  insertAcquis(input: $object) {
    celex
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertAcquisGQL extends Apollo.Mutation<InsertAcquisMutation, InsertAcquisMutationVariables> {
    override document = InsertAcquisDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAcquisDocument = gql`
    mutation updateAcquis($celex: String!, $set: pkie_acquis_set_input, $objects: [pkie_acquis_chapter_insert_input!]!) {
  update_pkie_acquis_by_pk(pk_columns: {celex: $celex}, _set: $set) {
    level_of_alignment
    is_aligned
    dms_id
  }
  insert_pkie_acquis_chapter(objects: $objects) {
    returning {
      celex
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAcquisGQL extends Apollo.Mutation<UpdateAcquisMutation, UpdateAcquisMutationVariables> {
    override document = UpdateAcquisDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertErrorLogsDocument = gql`
    mutation insertErrorLogs($object: error_logs_insert_input!) {
  insert_error_logs_one(object: $object) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertErrorLogsGQL extends Apollo.Mutation<InsertErrorLogsMutation, InsertErrorLogsMutationVariables> {
    override document = InsertErrorLogsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateErrorLogsByPkDocument = gql`
    mutation updateErrorLogsByPk($id: uuid!, $solved_date: timestamptz!) {
  update_error_logs_by_pk(
    pk_columns: {id: $id}
    _set: {solved: true, solved_date: $solved_date}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateErrorLogsByPkGQL extends Apollo.Mutation<UpdateErrorLogsByPkMutation, UpdateErrorLogsByPkMutationVariables> {
    override document = UpdateErrorLogsByPkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteGnpiesDocument = gql`
    mutation deleteGnpies($userId: uuid!) {
  delete_gnpie(where: {user_id: {_eq: $userId}}) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteGnpiesGQL extends Apollo.Mutation<DeleteGnpiesMutation, DeleteGnpiesMutationVariables> {
    override document = DeleteGnpiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserRoleDocument = gql`
    mutation updateUserRole($object: authUserRoles_set_input!, $id: uuid!) {
  updateAuthUserRole(_set: $object, pk_columns: {id: $id}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserRoleGQL extends Apollo.Mutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables> {
    override document = UpdateUserRoleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertMeasureBookmarkOneDocument = gql`
    mutation insertMeasureBookmarkOne($object: pkie_measure_bookmark_insert_input!) {
  insert_pkie_measure_bookmark_one(
    object: $object
    on_conflict: {constraint: measure_bookmark_pkey, update_columns: []}
  ) {
    id
    measure {
      id
      acquis_chapter {
        celex
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertMeasureBookmarkOneGQL extends Apollo.Mutation<InsertMeasureBookmarkOneMutation, InsertMeasureBookmarkOneMutationVariables> {
    override document = InsertMeasureBookmarkOneDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertMeasureRoadmapOneDocument = gql`
    mutation insertMeasureRoadmapOne($object: pkie_measure_roadmap_insert_input!) {
  insert_pkie_measure_roadmap_one(
    object: $object
    on_conflict: {constraint: measure_roadmap_pkey}
  ) {
    id
    measure {
      id
      acquis_chapter {
        celex
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertMeasureRoadmapOneGQL extends Apollo.Mutation<InsertMeasureRoadmapOneMutation, InsertMeasureRoadmapOneMutationVariables> {
    override document = InsertMeasureRoadmapOneDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteMeasureRoadmapDocument = gql`
    mutation deleteMeasureRoadmap($taskId: uuid!, $measureId: uuid!) {
  delete_pkie_measure_roadmap(
    where: {task_id: {_eq: $taskId}, measure_id: {_eq: $measureId}}
  ) {
    returning {
      task {
        id
        text
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteMeasureRoadmapGQL extends Apollo.Mutation<DeleteMeasureRoadmapMutation, DeleteMeasureRoadmapMutationVariables> {
    override document = DeleteMeasureRoadmapDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertTaskGnpieMemberDocument = gql`
    mutation insertTaskGnpieMember($object: task_gnpie_members_insert_input!) {
  insert_task_gnpie_members_one(object: $object) {
    id
    gnpie_member {
      id
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertTaskGnpieMemberGQL extends Apollo.Mutation<InsertTaskGnpieMemberMutation, InsertTaskGnpieMemberMutationVariables> {
    override document = InsertTaskGnpieMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTaskGnpieMemberDocument = gql`
    mutation deleteTaskGnpieMember($id: uuid!) {
  delete_task_gnpie_members_by_pk(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTaskGnpieMemberGQL extends Apollo.Mutation<DeleteTaskGnpieMemberMutation, DeleteTaskGnpieMemberMutationVariables> {
    override document = DeleteTaskGnpieMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertBookmarkTaskGnpieMemberDocument = gql`
    mutation insertBookmarkTaskGnpieMember($object: bookmark_gnpie_members_insert_input!) {
  insert_bookmark_gnpie_members_one(object: $object) {
    id
    gnpie_member {
      id
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertBookmarkTaskGnpieMemberGQL extends Apollo.Mutation<InsertBookmarkTaskGnpieMemberMutation, InsertBookmarkTaskGnpieMemberMutationVariables> {
    override document = InsertBookmarkTaskGnpieMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBookmarkTaskGnpieMemberDocument = gql`
    mutation deleteBookmarkTaskGnpieMember($id: uuid!) {
  delete_bookmark_gnpie_members_by_pk(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBookmarkTaskGnpieMemberGQL extends Apollo.Mutation<DeleteBookmarkTaskGnpieMemberMutation, DeleteBookmarkTaskGnpieMemberMutationVariables> {
    override document = DeleteBookmarkTaskGnpieMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertReportTaskGnpieMemberDocument = gql`
    mutation insertReportTaskGnpieMember($object: report_task_gnpie_members_insert_input!) {
  insert_report_task_gnpie_members_one(object: $object) {
    id
    gnpie_member {
      id
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertReportTaskGnpieMemberGQL extends Apollo.Mutation<InsertReportTaskGnpieMemberMutation, InsertReportTaskGnpieMemberMutationVariables> {
    override document = InsertReportTaskGnpieMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteReportTaskGnpieMemberDocument = gql`
    mutation deleteReportTaskGnpieMember($id: uuid!) {
  delete_report_task_gnpie_members_by_pk(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteReportTaskGnpieMemberGQL extends Apollo.Mutation<DeleteReportTaskGnpieMemberMutation, DeleteReportTaskGnpieMemberMutationVariables> {
    override document = DeleteReportTaskGnpieMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertPkieMeasureGnpieMemberDocument = gql`
    mutation insertPkieMeasureGnpieMember($object: pkie_measure_gnpie_members_insert_input!) {
  insert_pkie_measure_gnpie_members_one(object: $object) {
    id
    gnpie_member {
      id
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertPkieMeasureGnpieMemberGQL extends Apollo.Mutation<InsertPkieMeasureGnpieMemberMutation, InsertPkieMeasureGnpieMemberMutationVariables> {
    override document = InsertPkieMeasureGnpieMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePkieMeasureGnpieMemberDocument = gql`
    mutation deletePkieMeasureGnpieMember($id: uuid!) {
  delete_pkie_measure_gnpie_members_by_pk(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePkieMeasureGnpieMemberGQL extends Apollo.Mutation<DeletePkieMeasureGnpieMemberMutation, DeletePkieMeasureGnpieMemberMutationVariables> {
    override document = DeletePkieMeasureGnpieMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateNationalActDocument = gql`
    mutation updateNationalAct($eli: String!, $set: pkie_national_acts_set_input!) {
  update_pkie_national_acts_by_pk(_set: $set, pk_columns: {eli: $eli}) {
    eli
    type
    title
    qbz_id
    publish_date
    act_number
    dms_id
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateNationalActGQL extends Apollo.Mutation<UpdateNationalActMutation, UpdateNationalActMutationVariables> {
    override document = UpdateNationalActDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSubChapterDocument = gql`
    mutation updateSubChapter($set: sub_chapters_set_input!, $id: Int!) {
  update_sub_chapters_by_pk(_set: $set, pk_columns: {id: $id}) {
    ...Subchapter
  }
}
    ${SubchapterFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSubChapterGQL extends Apollo.Mutation<UpdateSubChapterMutation, UpdateSubChapterMutationVariables> {
    override document = UpdateSubChapterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTopicDocument = gql`
    mutation updateTopic($set: topics_set_input!, $id: Int!) {
  update_topics_by_pk(_set: $set, pk_columns: {id: $id}) {
    id
    title
    title_en
    active
    chapter_id
    subchapter_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTopicGQL extends Apollo.Mutation<UpdateTopicMutation, UpdateTopicMutationVariables> {
    override document = UpdateTopicDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSubchapterDocument = gql`
    mutation createSubchapter($object: sub_chapters_insert_input!) {
  insert_sub_chapters_one(object: $object) {
    ...Subchapter
  }
}
    ${SubchapterFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSubchapterGQL extends Apollo.Mutation<CreateSubchapterMutation, CreateSubchapterMutationVariables> {
    override document = CreateSubchapterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertTopicDocument = gql`
    mutation insertTopic($object: topics_insert_input!) {
  insert_topics_one(object: $object) {
    id
    title
    title_en
    active
    chapter_id
    subchapter_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertTopicGQL extends Apollo.Mutation<InsertTopicMutation, InsertTopicMutationVariables> {
    override document = InsertTopicDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteSubchapterDocument = gql`
    mutation deleteSubchapter($id: Int!) {
  delete_sub_chapters_by_pk(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSubchapterGQL extends Apollo.Mutation<DeleteSubchapterMutation, DeleteSubchapterMutationVariables> {
    override document = DeleteSubchapterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTopicDocument = gql`
    mutation deleteTopic($id: Int!) {
  delete_topics_by_pk(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTopicGQL extends Apollo.Mutation<DeleteTopicMutation, DeleteTopicMutationVariables> {
    override document = DeleteTopicDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertMeasureAcquisWorkplanTaskDocument = gql`
    mutation insertMeasureAcquisWorkplanTask($object: pkie_measure_acq_wpl_task_insert_input!) {
  insert_pkie_measure_acq_wpl_task_one(object: $object) {
    id
    acquis_work_plan_task {
      id
    }
    measure {
      id
      measure_acq_wpl_tasks {
        id
        measure_id
        aquis_work_plan_task_id
      }
      acquis_work_plan_task_id
      acquis_chapter {
        id
        celex
      }
      text
      year_of_completition
      type
      is_completed
      negotiator {
        id
        displayName
      }
      chief_gnpie_secretariat {
        id
        displayName
      }
      gnpie_secretariat {
        id
        displayName
      }
      created_by_user {
        id
        displayName
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertMeasureAcquisWorkplanTaskGQL extends Apollo.Mutation<InsertMeasureAcquisWorkplanTaskMutation, InsertMeasureAcquisWorkplanTaskMutationVariables> {
    override document = InsertMeasureAcquisWorkplanTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertWorkPlanTaskMemberDocument = gql`
    mutation insertWorkPlanTaskMember($object: pkie_work_plan_task_member_insert_input!) {
  insert_pkie_work_plan_task_member_one(object: $object) {
    id
    user {
      id
      displayName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertWorkPlanTaskMemberGQL extends Apollo.Mutation<InsertWorkPlanTaskMemberMutation, InsertWorkPlanTaskMemberMutationVariables> {
    override document = InsertWorkPlanTaskMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteMeasureAcquisWorkPlanTaskDocument = gql`
    mutation deleteMeasureAcquisWorkPlanTask($measureId: uuid!, $workPlanTaskId: uuid, $metadata: jsonb!) {
  comments: update_pkie_measure_acq_wpl_task_comment(
    where: {measure_acq_wpl_task: {acquis_work_plan_task: {work_plan_task_id: {_eq: $workPlanTaskId}}, measure_id: {_eq: $measureId}}}
    _set: {measure_acq_wpl_task_id: null, metadata: $metadata}
  ) {
    returning {
      id
    }
  }
  measure: delete_pkie_measure_acq_wpl_task(
    where: {measure_id: {_eq: $measureId}, acquis_work_plan_task: {work_plan_task_id: {_eq: $workPlanTaskId}}}
  ) {
    affected_rows
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteMeasureAcquisWorkPlanTaskGQL extends Apollo.Mutation<DeleteMeasureAcquisWorkPlanTaskMutation, DeleteMeasureAcquisWorkPlanTaskMutationVariables> {
    override document = DeleteMeasureAcquisWorkPlanTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteMemberFromWorkplanTaskDocument = gql`
    mutation deleteMemberFromWorkplanTask($id: uuid!) {
  delete_pkie_work_plan_task_member_by_pk(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteMemberFromWorkplanTaskGQL extends Apollo.Mutation<DeleteMemberFromWorkplanTaskMutation, DeleteMemberFromWorkplanTaskMutationVariables> {
    override document = DeleteMemberFromWorkplanTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangeMeasureWithCommentDocument = gql`
    mutation changeMeasureWithComment($measure_acq_wpl_task_id: uuid!, $comment: String, $status: pkie_measure_acq_wpl_task_status_enum!) {
  update_pkie_measure_acq_wpl_task_by_pk(
    _set: {status: $status}
    pk_columns: {id: $measure_acq_wpl_task_id}
  ) {
    id
  }
  insert_pkie_measure_acq_wpl_task_comment_one(
    object: {measure_acq_wpl_task_id: $measure_acq_wpl_task_id, comment: $comment, status: $status}
  ) {
    id
    comment
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangeMeasureWithCommentGQL extends Apollo.Mutation<ChangeMeasureWithCommentMutation, ChangeMeasureWithCommentMutationVariables> {
    override document = ChangeMeasureWithCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InsertReportTemplateDocument = gql`
    mutation insertReportTemplate($title: String!) {
  insertReportTemplate(template: {title: $title}) {
    template_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InsertReportTemplateGQL extends Apollo.Mutation<InsertReportTemplateMutation, InsertReportTemplateMutationVariables> {
    override document = InsertReportTemplateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCommentsDocument = gql`
    mutation deleteComments($measure_acq_wpl_task_id: uuid!, $metadata: jsonb!) {
  update_pkie_measure_acq_wpl_task_comment(
    where: {measure_acq_wpl_task_id: {_eq: $measure_acq_wpl_task_id}}
    _set: {measure_acq_wpl_task_id: null, metadata: $metadata}
  ) {
    returning {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCommentsGQL extends Apollo.Mutation<DeleteCommentsMutation, DeleteCommentsMutationVariables> {
    override document = DeleteCommentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }