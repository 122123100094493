export namespace UserAction {
  // SHARED
  export type SharedActionEnum = 'SELECT_CHAPTER' | 'FILTER_TABLE' | 'OPEN_MODAL' | 'SELECT_USER' | 'SELECT_CLUSTER' | 'EDIT_FIELD';
  export const SharedActions = {
    SELECT_CHAPTER: 'SELECT_CHAPTER' as SharedActionEnum,
    SELECT_CLUSTER: 'SELECT_CLUSTER' as SharedActionEnum,
    SELECT_USER: 'SELECT_USER' as SharedActionEnum,
    FILTER_TABLE: 'FILTER_TABLE' as SharedActionEnum,
    OPEN_MODAL: 'OPEN_MODAL' as SharedActionEnum,
    GENERATE_REPORT: 'GENERATE_REPORT' as SharedActionEnum,
    STORAGE_SPACE: 'STORAGE_SPACE' as SharedActionEnum,
    EDIT_FIELD: 'EDIT_FIELD' as SharedActionEnum,
  };
  // CHAPTER PASSPORT
  export type DashboardPassportActionsEnum = 'CHANGE_CHAPTER' | 'SHOW_WORK_PLAN_TASK_CHART';
  export const DashboardActions = {
    CHANGE_CHAPTER: 'CHANGE_CHAPTER' as DashboardPassportActionsEnum,
    SHOW_WORK_PLAN_TASK_CHART: 'SHOW_WORK_PLAN_TASK_CHART' as DashboardPassportActionsEnum,
  };
  // Report
  export type ReportActionsEnum = 'CHANGE_CHAPTER';
  export const ReportActions = {
    CHANGE_CHAPTER: 'CHANGE_CHAPTER' as ReportActionsEnum,
  };
  // PROGRESS REPORT VIEW
  export type ProgresReportActionEnum = 'ASSIGN_REPORTER' | 'ADD_PRIORITY' | 'REMOVE_PRIORITY' | 'GENERATE_REPORT' | 'CHANGE_STATUS' | 'ASSIGN_CHIEF_SECRETARIAT' | 'SEND_FOR_SIGNATURE' | 'SIGN_CHAPTER' | 'GENERATE_DOCUMENT' | 'ACTION_BUTTON' | 'VIEW_SIGNED_DOCUMENTS';
  export const ProgresReportViewActions = {
    ASSIGN_REPORTER: 'ASSIGN_REPORTER' as ProgresReportActionEnum,
    ASSIGN_CHIEF_SECRETARIAT: 'ASSIGN_CHIEF_SECRETARIAT' as ProgresReportActionEnum,
    ADD_PRIORITY: 'ADD_PRIORITY' as ProgresReportActionEnum,
    REMOVE_PRIORITY: 'REMOVE_PRIORITY' as ProgresReportActionEnum,
    GENERATE_REPORT: 'GENERATE_REPORT' as ProgresReportActionEnum,
    CHANGE_STATUS: 'CHANGE_STATUS' as ProgresReportActionEnum,
    VIEW_CHAPTERS: 'VIEW_CHAPTERS' as ProgresReportActionEnum,
    VIEW_SIGNED_DOCUMENTS : 'VIEW_SIGNED_DOCUMENTS' as ProgresReportActionEnum,
    SEND_FOR_SIGNATURE: 'SEND_FOR_SIGNATURE' as ProgresReportActionEnum,
    SIGN_CHAPTER: 'SIGN_CHAPTER' as ProgresReportActionEnum,
    GENERATE_DOCUMENT: 'GENERATE_DOCUMENT' as ProgresReportActionEnum,
    ACTION_BUTTON: 'ACTION_BUTTON' as ProgresReportActionEnum,
    EDIT_TEMPLATE: 'EDIT_TEMPLATE' as ProgresReportActionEnum,
  };
  // PROGRESS REPORT LIST
  export type ProgresReportListActionEnum = 'CREATE_REPORT';
  export const ProgresReportListActions = {
    CREATE_REPORT: 'CREATE_REPORT' as ProgresReportListActionEnum,
  };
  // BOOKMARK LIST
  export type BookmarkListActionEnum = 'CREATE_BOOKMARK' | 'EDIT_BOOKMARK' | 'DELETE_BOOKMARK' | 'GENERATE_DOCUMENT';
  export const BookmarkListActions = {
    CREATE_BOOKMARK: 'CREATE_BOOKMARK' as BookmarkListActionEnum,
    EDIT_BOOKMARK: 'EDIT_BOOKMARK' as BookmarkListActionEnum,
    DELETE_BOOKMARK: 'DELETE_BOOKMARK' as BookmarkListActionEnum,
    GENERATE_DOCUMENT: 'GENERATE_DOCUMENT' as BookmarkListActionEnum,
  };
  // BOOKMARK VIEW
  export type BookmarkViewActionEnum = 'CREATE_TASK';
  export const BookmarkViewActions = {
    CREATE_TASK: 'CREATE_TASK' as BookmarkViewActionEnum,
  };
  // BOOKMARK TASK
  export type BookmarkTaskActionsEnum = 'REMOVE_MEMBER';
  export const BookmarkTaskActions = {
    REMOVE_MEMBER: 'REMOVE_MEMBER' as BookmarkTaskActionsEnum,
  }
  // WORK PLAN VIEW
  export type WorkPlanViewActionEnum = 'ADD_ACQUIS' | 'DELETE_ACQUIS' | 'CHANGE_STATUS' | 'SIGN_DOCUMENT' | 'VIEW_SIGNED_DOCUMENTS';
  export const WorkPlanViewActions = {
    ADD_ACQUIS: 'ADD_ACQUIS' as WorkPlanViewActionEnum,
    DELETE_ACQUIS: 'DELETE_ACQUIS' as WorkPlanViewActionEnum,
    CHANGE_STATUS: 'CHANGE_STATUS' as WorkPlanViewActionEnum,
    SIGN_DOCUMENT: 'SIGN_DOCUMENT' as WorkPlanViewActionEnum,
    EDIT_MEMBERS: 'EDIT_MEMBERS' as WorkPlanViewActionEnum,
    RETURN_WITH_COMMENTS: 'RETURN_WITH_COMMENTS' as WorkPlanViewActionEnum,
    APPROVE_WITH_COMMENTS: 'APPROVE_WITH_COMMENTS' as WorkPlanViewActionEnum,
    VIEW_SIGNED_DOCUMENTS: 'VIEW_SIGNED_DOCUMENTS' as WorkPlanViewActionEnum,
  };
  // MONITORING
  export type MonitoringActionEnum = 'VIEW' | 'GENERATE_DOCUMENT' | 'CREATE_DOCUMENT';
  export const MonitoringAction = {
    VIEW: 'VIEW' as MonitoringActionEnum,
    GENERATE_DOCUMENT: 'GENERATE_DOCUMENT' as MonitoringActionEnum,
    CREATE_DOCUMENT: 'CREATE_DOCUMENT' as MonitoringActionEnum,
  };
  // BANK OF QUESTIONS
  export type BankOfQuestionsActionsEnum = 'CREATE_QUESTION' | 'DELETE_QUESTION';
  export const BankOfQuestionsActions = {
    CREATE_QUESTION: 'CREATE_QUESTION' as BankOfQuestionsActionsEnum,
    EDIT_QUESTION: 'EDIT_QUESTION' as BankOfQuestionsActionsEnum,
    DELETE_QUESTION: 'DELETE_QUESTION' as BankOfQuestionsActionsEnum,
  };
  // RECOMMENDATION LIST
  export type RecommendationListActionsEnum = 'CREATE_RECOMMENDATION';
  export const RecommendationListActions = {
    CREATE_RECOMMENDATION: 'CREATE_RECOMMENDATION' as RecommendationListActionsEnum,
  };
  // USER MANAGEMENT
  export type UserManagementActionsEnum = 'CREATE_USER' | 'UPDATE_USER';
  export const UserManagementActions = {
    CREATE_USER: 'CREATE_USER' as UserManagementActionsEnum,
    UPDATE_USER: 'UPDATE_USER' as UserManagementActionsEnum,
  };
  // INSTITUTION MANAGEMENT
  export type InstitutionsActionsEnum = 'CREATE_INSTITUTION' | 'DELETE_INSTITUTION';
  export const InstitutionsActions = {
    CREATE_INSTITUTION: 'CREATE_INSTITUTION' as InstitutionsActionsEnum,
    DELETE_INSTITUTION: 'DELETE_INSTITUTION' as InstitutionsActionsEnum,
  };
  // CHAPTER PASSPORT
  export type ChapterPassportActionsEnum = 'CHANGE_CHAPTER' | 'CHANGE_LEAD_INST' | 'CHANGE_SUBCHAPTER_LEAD';
  export const ChapterPassportActions = {
    CHANGE_CHAPTER: 'CHANGE_CHAPTER' as ChapterPassportActionsEnum,
    CHANGE_LEAD_INST: 'CHANGE_LEAD_INST' as ChapterPassportActionsEnum,
    CHANGE_SUBCHAPTER_LEAD: 'CHANGE_SUBCHAPTER_LEAD' as ChapterPassportActionsEnum,
  };
  // CALENDAR
  export type CalendarActionsEnum = 'CREATE_MEETING' | 'ADD_TASK' | 'ADD_COORIDNATORS_MEETING' | 'ADD_NEGOTIATORS_MEETING' | 'ADD_CHIEF_MEETING' | 'ADD_SPECIALISTS_MEETING' | 'ADD_MEMBERS_MEETING';
  export const CalendarActions = {
    CREATE_MEETING: 'CREATE_MEETING' as CalendarActionsEnum,
    ADD_TASK: 'ADD_TASK' as CalendarActionsEnum,
    ADD_COORDINATOR: 'ADD_COORDINATOR' as CalendarActionsEnum,
    ADD_NEGOTIATOR: 'ADD_NEGOTIATOR' as CalendarActionsEnum,
    ADD_CHIEF: 'ADD_CHIEF' as CalendarActionsEnum,
    ADD_SPECIALIST: 'ADD_SPECIALIST' as CalendarActionsEnum,
    ADD_MEMBER: 'ADD_MEMBER' as CalendarActionsEnum,
  };

  // WORK PLAN MANAGEMENT
  export type WorkPlanActionsEnum = 'CREATE_WORK_PLAN' | 'DELETE_WORK_PLAN' | 'UPDATE_WORK_PLAN' | 'DOWNLOAD_WORK_PLAN' | 'CHANGE_STATUS' | 'GENERATE_DOCUMENT';
  export const WorkPlanActions = {
    CREATE_WORK_PLAN: 'CREATE_WORK_PLAN' as WorkPlanActionsEnum,
    DELETE_WORK_PLAN: 'DELETE_WORK_PLAN' as WorkPlanActionsEnum,
    UPDATE_WORK_PLAN: 'UPDATE_WORK_PLAN' as WorkPlanActionsEnum,
    DOWNLOAD_WORK_PLAN: 'DOWNLOAD_WORK_PLAN' as WorkPlanActionsEnum,
    CHANGE_STATUS: 'CHANGE_STATUS' as WorkPlanActionsEnum,
    GENERATE_DOCUMENT: 'GENERATE_DOCUMENT' as WorkPlanActionsEnum,
  };

  export type AcquisActionEnum = 'ADD_ACQUIS' | 'ALERT_VIEW' | 'EDIT_ACQUIS' | 'VIEW_LOGGED_CHAPTER';
  export const AcquisActions = {
    ADD_ACQUIS: 'ADD_ACQUIS' as AcquisActionEnum,
    EDIT_ACQUIS: 'EDIT_ACQUIS' as AcquisViewActionEnum,
    ALERT_VIEW: 'ALERT_VIEW' as AcquisViewActionEnum,
    VIEW_LOGGED_CHAPTER: 'VIEW_LOGGED_CHAPTER' as AcquisViewActionEnum,
  };

  export type AcquisViewActionEnum = 'CREATE_TASK' | 'CHANGE_STATUS' | 'ADD_INSTITUTION' | 'DELETE_INSTITUTION' | 'ADD_ACT' | 'ADD_LGA' | 'ADD_IAGA' | 'DELETE_NATIONAL_ACT' | 'GENERATE_DOCUMENT'  ;
  export const AcquisViewActions = {
    CREATE_TASK: 'CREATE_TASK' as AcquisViewActionEnum,
    CHANGE_STATUS: 'CHANGE_STATUS' as AcquisViewActionEnum,
    ADD_INSTITUTION: 'ADD_INSTITUTION' as AcquisViewActionEnum,
    DELETE_INSTITUTION: 'DELETE_INSTITUTION' as AcquisViewActionEnum,
    ADD_ACT: 'ADD_ACT' as AcquisViewActionEnum,
    ADD_LGA: 'ADD_LGA' as AcquisViewActionEnum,
    ADD_IAGA: 'ADD_IAGA' as AcquisViewActionEnum,
    DELETE_NATIONAL_ACT: 'DELETE_NATIONAL_ACT' as AcquisViewActionEnum,
  };
  export type IAGAActionEnum = 'GENERATE_DOCUMENT';
  export const IAGAViewActions = {
    GENERATE_DOCUMENT: 'GENERATE_DOCUMENT' as IAGAActionEnum,
  };
  export type LGAActionEnum = 'GENERATE_DOCUMENT';
  export const LGAViewActions = {
    GENERATE_DOCUMENT: 'GENERATE_DOCUMENT' as LGAActionEnum,
  };
  export type AcquisTaskViewActionEnum = 'CHANGE_STATUS' | 'UPDATE_ASSIGNEE' | 'UPDATE_LEVEL_ALIGNMENT' | 'ADD_BUDGET' | 'DELETE_BUDGET' | 'ADD_BOOKMARK' | 'ADD_NATIONAL_ACT' | 'REMOVE_MEMBER';
  export const AcquisTaskViewActions = {
    CHANGE_STATUS: 'CHANGE_STATUS' as AcquisTaskViewActionEnum,
    UPDATE_ASSIGNEE: 'UPDATE_ASSIGNEE' as AcquisTaskViewActionEnum,
    UPDATE_LEVEL_ALIGNMENT: 'UPDATE_LEVEL_ALIGNMENT' as AcquisTaskViewActionEnum,
    UPDATE_TYPE_ALIGNMENT: 'UPDATE_type_ALIGNMENT' as AcquisTaskViewActionEnum,
    UPDATE_IMPACT_AREA: 'UPDATE_IMPACT_AREA' as AcquisTaskViewActionEnum,
    UPDATE_TEXT: 'UPDATE_TEXT' as AcquisTaskViewActionEnum,
    ADD_BUDGET: 'ADD_BUDGET' as AcquisTaskViewActionEnum,
    DELETE_BUDGET: 'DELETE_BUDGET' as AcquisTaskViewActionEnum,
    ADD_BOOKMARK: 'ADD_BOOKMARK' as AcquisTaskViewActionEnum,
    ADD_NATIONAL_ACT: 'ADD_NATIONAL_ACT' as AcquisTaskViewActionEnum,
    REMOVE_MEMBER: 'REMOVE_MEMBER' as AcquisTaskViewActionEnum,
  };
  // EXPERTS
  export type ExpertsActionEnum = 'ADD_EXPERT' | 'UPDATE_EXPERT' | 'DELETE_EXPERT' | 'GENERATE_EXPERT';
  export const ExpertsActions = {
    ADD_EXPERT: 'ADD_EXPERT' as ExpertsActionEnum,
    UPDATE_EXPERT: 'UPDATE_EXPERT' as ExpertsActionEnum,
    DELETE_EXPERT: 'DELETE_EXPERT' as ExpertsActionEnum,
    GENERATE_EXPERT: 'GENERATE_EXPERT' as ExpertsActionEnum,
  };
  // DONATORS
  export type DonatorsActionsEnum = 'ADD_DONATOR' | 'UPDATE_DONATOR' | 'DELETE_DONATOR' | 'GENERATE_REPORT';
  export const DonatorsActions = {
    ADD_DONATOR: 'ADD_DONATOR' as DonatorsActionsEnum,
    UPDATE_DONATOR: 'UPDATE_DONATOR' as DonatorsActionsEnum,
    DELETE_DONATOR: 'DELETE_DONATOR' as DonatorsActionsEnum,
    GENERATE_REPORT: 'GENERATE_REPORT' as DonatorsActionsEnum,
  };
  // PROJECTS
  export type ProjectsActionsEnum = 'ADD_PROJECT' | 'UPDATE_PROJECT' | 'DELETE_PROJECT' | 'GENERATE_REPORT';
  export const ProjectsActions = {
    ADD_PROJECT: 'ADD_PROJECT' as ProjectsActionsEnum,
    UPDATE_PROJECT: 'UPDATE_PROJECT' as ProjectsActionsEnum,
    DELETE_PROJECT: 'DELETE_PROJECT' as ProjectsActionsEnum,
    GENERATE_REPORT: 'GENERATE_REPORT' as ProjectsActionsEnum,
  };
  export type ProjectViewActionEnum = 'DELETE_PROJECT';
  export const ProjectViewActions = {
    DELETE_PROJECT: 'DELETE_PROJECT' as ProjectViewActionEnum,
  };
  // PRIORITY LIST
  export type PriorityListActionsEnum = 'ADD_PRIORITY_LIST' | 'UPDATE_PRIORITY_LIST' | 'DELETE_PRIORITY_LIST' | 'GENERATE_REPORT';
  export const PriorityListActions = {
    ADD_PRIORITY_LIST: 'ADD_PRIORITY_LIST' as PriorityListActionsEnum,
    UPDATE_PRIORITY_LIST: 'UPDATE_PRIORITY_LIST' as PriorityListActionsEnum,
    DELETE_PRIORITY_LIST: 'DELETE_PRIORITY_LIST' as PriorityListActionsEnum,
    GENERATE_REPORT: 'GENERATE_REPORT' as PriorityListActionsEnum,
  };

  // TRAINING NEEDS
  export type TrainingNeedsActionsEnum = 'ADD_TRAINING_NEEDS' | 'UPDATE_TRAINING_NEEDS' | 'DELETE_TRAINING_NEEDS' | 'APPROVE_TRAINING_NEEDS' | 'DISAPPROVE_TRAINING_NEEDS';
  export const TrainingNeedsActions = {
    ADD_TRAINING_NEEDS: 'ADD_TRAINING_NEEDS' as TrainingNeedsActionsEnum,
    UPDATE_TRAINING_NEEDS: 'UPDATE_TRAINING_NEEDS' as TrainingNeedsActionsEnum,
    APPROVE_TRAINING_NEEDS: 'APPROVE_TRAINING_NEEDS' as TrainingNeedsActionsEnum,
    DISAPPROVE_TRAINING_NEEDS: 'DISAPPROVE_TRAINING_NEEDS' as TrainingNeedsActionsEnum,
    ADD_TAIEX: 'ADD_TAIEX' as TrainingNeedsActionsEnum,
    ADD_TWINNING: 'ADD_TWINNING' as TrainingNeedsActionsEnum,
  };

  // TAIEX NEEDS
  export type TaiexNeedsActionsEnum = 'REMOVE_TRAINING_NEED';
  export const TaiexNeedsActions = {
    REMOVE_TRAINING_NEED: 'REMOVE_TRAINING_NEED' as TaiexNeedsActionsEnum,
  };

  // TWINNING NEEDS
  export type TwinningNeedsActionsEnum = 'REMOVE_TRAINING_NEED';
  export const TwinningNeedsActions = {
    REMOVE_TRAINING_NEED: 'REMOVE_TRAINING_NEED' as TwinningNeedsActionsEnum,
  };

  // TAIEX
  export type TaiexActionsEnum = 'ADD_TAIEX' | 'UPDATE_TAIEX' | 'DELETE_TAIEX';
  export const TaiexActions = {
    ADD_TAIEX: 'ADD_TAIEX' as TaiexActionsEnum,
    UPDATE_TAIEX: 'UPDATE_TAIEX' as TaiexActionsEnum,
    DELETE_TAIEX: 'DELETE_TAIEX' as TaiexActionsEnum,
  };

  // TWINNING
  export type TwinningActionsEnum = 'ADD_TWINNING' | 'UPDATE_TWINNING' | 'DELETE_TWINNING';
  export const TwinningActions = {
    ADD_TWINNING: 'ADD_TWINNING' as TwinningActionsEnum,
    UPDATE_TWINNING: 'UPDATE_TWINNING' as TwinningActionsEnum,
    DELETE_TWINNING: 'DELETE_TWINNING' as TwinningActionsEnum,
  };

  // GNPIE TRAINING NEED
  export type GnpieTrainingNeedActionsEnum = 'ADD_TRAINING_NEED' | 'UPDATE_TRAINING_NEED';
  export const GnpieTrainingNeedActions = {
    ADD_TRAINING_NEED: 'ADD_TRAINING_NEED' as GnpieTrainingNeedActionsEnum,
    UPDATE_TRAINING_NEED: 'UPDATE_TRAINING_NEED' as GnpieTrainingNeedActionsEnum,
  };

  // INTEGRATION CASE
  export type IntegrationCaseActionsEnum = 'CREATE_TASK';
  export const IntegrationCaseActions = {
    CREATE_TASK: 'CREATE_TASK' as IntegrationCaseActionsEnum,
  }

  // INTEGRATION TASK
  export type IntegrationTaskActionsEnum = 'REMOVE_MEMBER';
  export const IntegrationTaskActions = {
    REMOVE_MEMBER: 'REMOVE_MEMBER' as IntegrationTaskActionsEnum,
  }

  // Integration
  export type IntegrationActionsEnum = 'CHANGE_STATUS' | 'CREATE_DOCUMENT' | 'CHANGE_CHAPTER' | 'SIGN_CHAPTER' | 'GENERATE_DOCUMENT' | 'CHANGE_NEGOTIATOR' | 'CHANGE_CHIEF' | 'VIEW_SIGNED_DOCUMENTS';
  export const IntegrationActions = {
    CHANGE_STATUS: 'CHANGE_STATUS' as IntegrationActionsEnum,
    CREATE_DOCUMENT: 'CREATE_DOCUMENT' as IntegrationActionsEnum,
    GENERATE_DOCUMENT: 'GENERATE_DOCUMENT' as IntegrationActionsEnum,
    CHANGE_CHAPTER: 'CHANGE_CHAPTER' as IntegrationActionsEnum,
    SIGN_CHAPTER: 'SIGN_CHAPTER' as IntegrationActionsEnum,
    CHANGE_NEGOTIATOR: 'CHANGE_NEGOTIATOR' as IntegrationActionsEnum,
    CHANGE_CHIEF: 'CHANGE_CHIEF' as IntegrationActionsEnum,
    VIEW_SIGNED_DOCUMENTS: 'VIEW_SIGNED_DOCUMENTS' as IntegrationActionsEnum,
  };

  // DOCUMENTS
  export type DocumentsActionsEnum = 'ADD_DOCUMENT' | 'UPDATE_DOCUMENT' | 'DELETE_DOCUMENT';
  export const DocumentsActions = {
    ADD_DOCUMENT: 'ADD_DOCUMENT' as DocumentsActionsEnum,
    UPDATE_DOCUMENT: 'UPDATE_DOCUMENT' as DocumentsActionsEnum,
    DELETE_DOCUMENT: 'DELETE_DOCUMENT' as DocumentsActionsEnum,
  };

    // Roadmap
    export type RoadmapListActionsEnum = 'CHANGE_STATUS' | 'CREATE_DOCUMENT' | 'CHANGE_CHAPTER' | 'SIGN_CHAPTER' | 'CREATE_TASK' | 'GENERATE_REPORT' | 'SEND_FOR_SIGNATURE' | 'GENERATE_DOCUMENT' | 'VIEW_SIGNED_DOCUMENTS' | 'CHANGE_NEGOTIATOR' | 'CHANGE_CHIEF' | 'VIEW_COORDINATOR';
    export const RoadmapListActions = {
      CHANGE_STATUS: 'CHANGE_STATUS' as RoadmapListActionsEnum,
      CREATE_DOCUMENT: 'CREATE_DOCUMENT' as RoadmapListActionsEnum,
      CREATE_TASK: 'CREATE_TASK' as RoadmapListActionsEnum,
      CHANGE_CHAPTER: 'CHANGE_CHAPTER' as RoadmapListActionsEnum,
      SIGN_CHAPTER: 'SIGN_CHAPTER' as RoadmapListActionsEnum,
      GENERATE_REPORT: 'GENERATE_REPORT' as RoadmapListActionsEnum,
      SEND_FOR_SIGNATURE: 'SEND_FOR_SIGNATURE' as RoadmapListActionsEnum,
      GENERATE_DOCUMENT: 'GENERATE_DOCUMENT' as RoadmapListActionsEnum,
      VIEW_SIGNED_DOCUMENTS: 'VIEW_SIGNED_DOCUMENTS' as RoadmapListActionsEnum,
      CHANGE_NEGOTIATOR: 'CHANGE_NEGOTIATOR' as IntegrationActionsEnum,
      CHANGE_CHIEF: 'CHANGE_CHIEF' as IntegrationActionsEnum,
      VIEW_COORDINATOR: 'VIEW_COORDINATOR' as IntegrationActionsEnum,
    };
    // ROADDMAP TASK
    export type RoadmapTaskActionsEnum = 'CREATE_TASK';
    export const RoadmapTaskActions = {
      CREATE_TASK: 'CREATE_TASK' as RoadmapTaskActionsEnum,
    }
    // ROADDMAP SUBTASK
    export type RoadmapSubtaskActionsEnum = 'REMOVE_MEMBER';
    export const RoadmapSubtaskActions = {
      REMOVE_MEMBER: 'REMOVE_MEMBER' as RoadmapSubtaskActionsEnum,
    }

    export type SupportFieldsActionsEnum = 'ADD_CATEGORY' | 'ADD_FIELD';
    export const SupportFieldsActions = {
      ADD_CATEGORY: 'ADD_CATEGORY' as SupportFieldsActionsEnum,
      ADD_FIELD: 'ADD_FIELD' as SupportFieldsActionsEnum,
    }

    // ALBANIAN LAWS
    export type AlbanianLawsActionsEnum = 'VIEW_LAWS' | 'ADD_ACT' | 'CHANGE_CHAPTER' | 'EDIT_ACT';
    export const AlbanianLawsActions = {
      EDIT_ACT: 'EDIT_ACT' as AlbanianLawsActionsEnum,
      VIEW_LAWS: 'VIEW_LAWS' as AlbanianLawsActionsEnum,
      ADD_ACT: 'ADD_ACT' as AlbanianLawsActionsEnum,
      CHANGE_CHAPTER: 'CHANGE_CHAPTER' as SupportFieldsActionsEnum,
    }

    // TOPIC TREE
    export type TopicTreeActionsEnum = 'CHANGE';
    export const TopicTreeActions = {
      CHANGE: 'CHANGE' as TopicTreeActionsEnum,
    }
}

export type UserActionEnum =
  | UserAction.BookmarkTaskActionsEnum
  | UserAction.IntegrationTaskActionsEnum
  | UserAction.RoadmapSubtaskActionsEnum 
  | UserAction.AcquisActionEnum
  | UserAction.RoadmapTaskActionsEnum
  | UserAction.RoadmapListActionsEnum
  | UserAction.IntegrationCaseActionsEnum
  | UserAction.AcquisTaskViewActionEnum
  | UserAction.AcquisViewActionEnum
  | UserAction.WorkPlanViewActionEnum
  | UserAction.MonitoringActionEnum
  | UserAction.ChapterPassportActionsEnum
  | UserAction.InstitutionsActionsEnum
  | UserAction.UserManagementActionsEnum
  | UserAction.RecommendationListActionsEnum
  | UserAction.BankOfQuestionsActionsEnum
  | UserAction.BookmarkListActionEnum
  | UserAction.BookmarkViewActionEnum
  | UserAction.ProgresReportActionEnum
  | UserAction.ProgresReportListActionEnum
  | UserAction.SharedActionEnum
  | UserAction.WorkPlanActionsEnum
  | UserAction.ExpertsActionEnum
  | UserAction.DonatorsActionsEnum
  | UserAction.ProjectsActionsEnum
  | UserAction.PriorityListActionsEnum
  | UserAction.TrainingNeedsActionsEnum
  | UserAction.TaiexActionsEnum
  | UserAction.TwinningActionsEnum
  | UserAction.ProjectViewActionEnum
  | UserAction.IntegrationActionsEnum
  | UserAction.CalendarActionsEnum
  | UserAction.GnpieTrainingNeedActionsEnum
  | UserAction.DocumentsActionsEnum
  | UserAction.AlbanianLawsActionsEnum
